import React, {Component} from "react";
import {MDBCol, MDBContainer, MDBFooter, MDBIcon, MDBRow} from "mdbreact";
import {FaEdit, FaEnvelope, FaHome, FaPhone} from "react-icons/fa";
import {BrowserView, MobileView} from "react-device-detect";
import {withTranslation} from "react-i18next";


class Footer extends Component{

    constructor(props) {
        super(props);

        this.state = {
            isEnglish: this.props.isEnglish
        }
    }

    mobileView(){
        var lang = localStorage.getItem("lang")
        if (lang === null) {
            lang = 'fa'
        }
        if (lang === 'en'){
            console.log('en')
            return(
                <MDBFooter style={{backgroundColor: '#355070'}} className='text-center text-lg-start text-muted p-4 ltr'>
                    <section className=''>
                        <MDBContainer className='text-left text-md-end mt-3 text-white'>
                            <MDBRow className='mt-2 text-left'>

                                <h6 className='text-uppercase fw-bold mb-4 text-left'>{this.props.t("contact_us_footer_title")}</h6>
                                <hr style={{width:'100%', backgroundColor: '#fff', height: '2px'}}/>

                                <a href="mailto: marriage@hedayatcentre.org" style={{all: 'unset'}}><label className={'mb-2'}><FaEnvelope/> marriage@hedayatcentre.org</label></a>
                                <a href="tel:+447593999124" style={{all: 'unset'}}><label className={'mb-2'} style={{direction: 'ltr'}}><FaPhone/> + 44 7593 999 124  </label></a>
                                <br/>

                                <label className={'p-0 mb-4 text-left'}><FaHome style={{marginRight: '2px'}}/>{this.props.t("address_footer_title")}</label>
                            </MDBRow>


                            <MDBRow className='mt-2'>
                                <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("links_footer_title")}</h6>
                                <hr style={{width:'100%', backgroundColor: '#fff', height: '2px'}}/>

                                <MDBRow>
                                    <a style={{all: 'unset',  cursor: 'pointer', pointerEvents: 'auto'}} href='/about-us'><label>{this.props.t("aboutus")}</label></a>
                                </MDBRow>
                                <MDBRow>
                                    <a  style={{all: 'unset',  cursor: 'pointer', pointerEvents: 'auto'}} href='/terms'><label>{this.props.t("terms")}</label></a>
                                </MDBRow>
                                <MDBRow>
                                    <a  style={{all: 'unset',  cursor: 'pointer', pointerEvents: 'auto'}} href='/privacy'><label>{this.props.t("privacy")}</label></a>
                                </MDBRow>
                            </MDBRow>

                            <MDBRow className={'mt-4'}>
                                <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("socials_footer_title")}</h6>
                                <hr style={{width:'100%', backgroundColor: '#fff', height: '2px'}}/>
                                <div className={'text-left'}>
                                    <a target="_blank" rel="noopener noreferrer" href='https://chat.whatsapp.com/I4cEKmZeNLPAnnbk2YH856' className='me-4 text-reset'>
                                        <MDBIcon fab icon="whatsapp" />
                                    </a>

                                    <a target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/channel/UCIHPJ_lCxTHhy5zpGuiGbWg' className='me-4 text-reset'>
                                        <MDBIcon fab icon="youtube" />
                                    </a>
                                    <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/invites/contact/?i=1cccgzxuiqj00&utm_content=g27sif5' className='me-4 text-reset'>
                                        <MDBIcon fab icon="instagram" />
                                    </a>
                                </div>
                            </MDBRow>

                            <MDBRow className='mx-auto text-left mb-4'>
                                <div className={'text-center'}>
                                    <a href='/'><img width={'40%'} className={'img-fluid'} src="../images/Logo-c-w.png" /></a>
                                </div>
                            </MDBRow>

                        </MDBContainer>
                    </section>

                    <div className='text-center p-0 text-white mt-5' style={{fontSize: '12px'}}>
                        2023 © Copyright:
                        <a className='text-reset fw-bold' href='https://hedayatcentre.org/'>
                            hedayatcentre.org
                        </a>
                    </div>
                </MDBFooter>

            );
        }else {
            return (
                <MDBFooter style={{backgroundColor: '#355070'}} className='text-center text-lg-start text-muted p-4'>
                    <section className=''>
                        <MDBContainer className='text-right text-md-end mt-5 text-white'>
                            <MDBRow className='mt-2'>

                                <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("contact_us_footer_title")}</h6>
                                <hr style={{width: '100%', backgroundColor: '#fff', height: '2px'}}/>

                                <a href="mailto: marriage@hedayatcentre.org" style={{all: 'unset'}}><label
                                    className={'mb-2'}><FaEnvelope/> marriage@hedayatcentre.org</label></a>
                                <a href="tel:+447593999124" style={{all: 'unset'}}><label className={'mb-2'}
                                                                                          style={{direction: 'ltr'}}> +
                                    44 7593 999 124 <FaPhone/></label></a>

                                <label className={'p-0 mb-4'}><FaHome/>{this.props.t("address_footer_title")}</label>
                            </MDBRow>


                            <MDBRow>
                                <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("links_footer_title")}</h6>
                                <hr style={{width: '100%', backgroundColor: '#fff', height: '2px'}}/>

                                <MDBRow>
                                    <a style={{all: 'unset', cursor: 'pointer', pointerEvents: 'auto'}}
                                       href='/about-us'><label>{this.props.t("aboutus")}</label></a>
                                </MDBRow>
                                <MDBRow>
                                    <a style={{all: 'unset', cursor: 'pointer', pointerEvents: 'auto'}}
                                       href='/terms'><label>{this.props.t("terms")}</label></a>
                                </MDBRow>
                                <MDBRow>
                                    <a style={{all: 'unset', cursor: 'pointer', pointerEvents: 'auto'}} href='/privacy'><label>{this.props.t("privacy")}</label></a>
                                </MDBRow>
                            </MDBRow>

                            <MDBRow className={'mt-4'}>
                                <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("socials_footer_title")}</h6>
                                <hr style={{width: '100%', backgroundColor: '#fff', height: '2px'}}/>
                                <div className={'text-left'}>
                                    <a target="_blank" rel="noopener noreferrer"
                                       href='https://chat.whatsapp.com/I4cEKmZeNLPAnnbk2YH856'
                                       className='me-4 text-reset'>
                                        <MDBIcon fab icon="whatsapp"/>
                                    </a>

                                    <a target="_blank" rel="noopener noreferrer"
                                       href='https://www.youtube.com/channel/UCIHPJ_lCxTHhy5zpGuiGbWg'
                                       className='me-4 text-reset'>
                                        <MDBIcon fab icon="youtube"/>
                                    </a>
                                    <a target="_blank" rel="noopener noreferrer"
                                       href='https://www.instagram.com/invites/contact/?i=1cccgzxuiqj00&utm_content=g27sif5'
                                       className='me-4 text-reset'>
                                        <MDBIcon fab icon="instagram"/>
                                    </a>
                                </div>
                            </MDBRow>

                            <MDBRow className='mx-auto text-left mb-4'>
                                <div className={'text-center'}>
                                    <a href='/'><img width={'40%'} className={'img-fluid'}
                                                     src="../images/Logo-c-w.png"/></a>
                                </div>
                            </MDBRow>

                        </MDBContainer>
                    </section>

                    <div className='text-center p-0 text-white mt-5' style={{fontSize: '12px'}}>
                        2023 © Copyright:
                        <a className='text-reset fw-bold' href='https://hedayatcentre.org/'>
                            hedayatcentre.org
                        </a>
                    </div>
                </MDBFooter>

            );
        }

    }
    desktopView(){
        var lang = localStorage.getItem("lang")
        if (lang === null) {
            lang = 'fa'
        }
        if (lang === 'en'){

            return(

                <MDBFooter style={{backgroundColor: '#355070'}} className='text-center text-lg-start text-muted p-4 ltr'>
                    <section className=''>
                        <MDBContainer className='text-left text-md-end mt-5 text-white'>
                            <MDBRow className='mt-3'>
                                <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-4 text-left'>
                                    <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("contact_us_footer_title")}</h6>
                                    <hr style={{width:'100%', backgroundColor: '#fff', height: '2px'}}/>

                                    <a href="mailto: marriage@hedayatcentre.org" style={{all: 'unset'}}><label className={'mb-2'}><FaEnvelope/> marriage@hedayatcentre.org</label></a>
                                    <a href="tel:+447593999124" style={{all: 'unset'}}><label className={'mb-2'} > <FaPhone/> + 44 7593 999 124  </label></a>
                                    <br/>
                                    <label><FaHome/> {this.props.t("address_footer_title")}</label>
                                </MDBCol>


                                <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-4 text-left'>
                                    <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("links_footer_title")}</h6>
                                    <hr style={{width:'100%', backgroundColor: '#fff', height: '2px'}}/>

                                    <MDBRow>
                                        <a style={{all: 'unset',  cursor: 'pointer', pointerEvents: 'auto'}} href='/about-us'><label>{this.props.t("aboutus")}</label></a>
                                    </MDBRow>
                                    <MDBRow>
                                        <a  style={{all: 'unset',  cursor: 'pointer', pointerEvents: 'auto'}} href='/terms'><label>{this.props.t("terms")}</label></a>
                                    </MDBRow>
                                    <MDBRow>
                                        <a  style={{all: 'unset',  cursor: 'pointer', pointerEvents: 'auto'}} href='/privacy'><label>{this.props.t("privacy")}</label></a>
                                    </MDBRow>
                                </MDBCol>

                                <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-4 text-left'>
                                    <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("socials_footer_title")}</h6>
                                    <hr style={{width:'100%', backgroundColor: '#fff', height: '2px'}}/>
                                    <div className={'text-left'}>
                                        <a target="_blank" rel="noopener noreferrer" href='https://chat.whatsapp.com/I4cEKmZeNLPAnnbk2YH856' className='me-4 text-reset'>
                                            <MDBIcon fab size='lg' icon="whatsapp" />
                                        </a>

                                        <a target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/channel/UCIHPJ_lCxTHhy5zpGuiGbWg' className='me-4 text-reset'>
                                            <MDBIcon fab size='lg' icon="youtube" />
                                        </a>
                                        <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/invites/contact/?i=1cccgzxuiqj00&utm_content=g27sif5' className='me-4 text-reset'>
                                            <MDBIcon fab size='lg' icon="instagram" />
                                        </a>
                                    </div>
                                </MDBCol>

                                <MDBCol md="4" lg="3" xl="3" className='mx-auto text-left mb-4'>
                                    <img width={'150'} src="../images/Logo-c-w.png" style={{marginLeft: '80px'}}/>

                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>

                    <div className='text-center p-0 text-white' style={{fontSize: '12px'}}>
                        2023 © Copyright:
                        <a className='text-reset fw-bold' href='https://hedayatcentre.org/'>
                            hedayatcentre.org
                        </a>
                    </div>
                </MDBFooter>

            );
        }else {
            return (

                <MDBFooter style={{backgroundColor: '#355070'}} className='text-center text-lg-start text-muted p-4'>
                    <section className=''>
                        <MDBContainer className='text-right text-md-end mt-5 text-white'>
                            <MDBRow className='mt-3'>
                                <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("contact_us_footer_title")}</h6>
                                    <hr style={{width: '100%', backgroundColor: '#fff', height: '2px'}}/>

                                    <a href="mailto: marriage@hedayatcentre.org" style={{all: 'unset'}}><label
                                        className={'mb-2'}><FaEnvelope/> marriage@hedayatcentre.org</label></a>
                                    <a href="tel:+447593999124" style={{all: 'unset'}}><label className={'mb-2'}
                                                                                              style={{direction: 'ltr'}}> +
                                        44 7593 999 124 <FaPhone/></label></a>
                                    <br/>
                                    <label><FaHome/> {this.props.t("address_footer_title")}</label>
                                </MDBCol>


                                <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("links_footer_title")}</h6>
                                    <hr style={{width: '100%', backgroundColor: '#fff', height: '2px'}}/>

                                    <MDBRow>
                                        <a style={{all: 'unset', cursor: 'pointer', pointerEvents: 'auto'}}
                                           href='/about-us'><label>{this.props.t("aboutus")}</label></a>
                                    </MDBRow>
                                    <MDBRow>
                                        <a style={{all: 'unset', cursor: 'pointer', pointerEvents: 'auto'}}
                                           href='/terms'><label>{this.props.t("terms")}</label></a>
                                    </MDBRow>
                                    <MDBRow>
                                        <a style={{all: 'unset', cursor: 'pointer', pointerEvents: 'auto'}}
                                           href='/privacy'><label>{this.props.t("privacy")}</label></a>
                                    </MDBRow>
                                </MDBCol>

                                <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("socials_footer_title")}</h6>
                                    <hr style={{width: '100%', backgroundColor: '#fff', height: '2px'}}/>
                                    <div className={'text-left'}>
                                        <a target="_blank" rel="noopener noreferrer"
                                           href='https://chat.whatsapp.com/I4cEKmZeNLPAnnbk2YH856'
                                           className='me-4 text-reset'>
                                            <MDBIcon fab size='lg' icon="whatsapp"/>
                                        </a>

                                        <a target="_blank" rel="noopener noreferrer"
                                           href='https://www.youtube.com/channel/UCIHPJ_lCxTHhy5zpGuiGbWg'
                                           className='me-4 text-reset'>
                                            <MDBIcon fab size='lg' icon="youtube"/>
                                        </a>
                                        <a target="_blank" rel="noopener noreferrer"
                                           href='https://www.instagram.com/invites/contact/?i=1cccgzxuiqj00&utm_content=g27sif5'
                                           className='me-4 text-reset'>
                                            <MDBIcon fab size='lg' icon="instagram"/>
                                        </a>
                                    </div>
                                </MDBCol>

                                <MDBCol md="4" lg="3" xl="3" className='mx-auto text-right mb-4'>
                                    <img width={'150'} src="../images/Logo-c-w.png" style={{marginRight: '150px'}}/>

                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>

                    <div className='text-center p-0 text-white' style={{fontSize: '12px'}}>
                        2023 © Copyright:
                        <a className='text-reset fw-bold' href='https://hedayatcentre.org/'>
                            hedayatcentre.org
                        </a>
                    </div>
                </MDBFooter>

            );
        }
    }
    render() {
        return(
            <div>
                <BrowserView>
                    {this.desktopView()}
                </BrowserView>
                <MobileView>
                    {this.mobileView()}
                </MobileView>
            </div>
        )
    }
}

export default withTranslation()(Footer);