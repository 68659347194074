import {Component} from "react";
import {
    MDBCarousel,
    MDBCarouselItem,
} from 'mdb-react-ui-kit';
import ErrorToGetData from "./ErrorToGetData";



class NewSlider extends Component{

    render() {
        if(this.props.sliders !== undefined && this.props.sliders.length>0) {
            return (

                <MDBCarousel showIndicators showControls dealy={5000} fade>
                    {this.props.sliders.map((slideImage, index) => (
                        this.getImageSlider(slideImage, index)
                    ))}
                </MDBCarousel>

            );
        }
        return (
            <div>

            </div>
        );
    }

    getImageSlider(slideImage, index){
        if(slideImage.url != null) {
            return (
                <a href={slideImage.url}>
                    <MDBCarouselItem
                        className='w-100 d-block'
                        itemId={index+1}
                        src={slideImage.image}
                        alt={slideImage.alt}
                        key={index+1}
                    >
                    </MDBCarouselItem>
                </a>

            )
        }else {
            return (

                <MDBCarouselItem
                    className='w-100 d-block'
                    itemId={index+1}
                    key={index+1}
                    src={slideImage.image}
                    alt={slideImage.alt}
                >
                </MDBCarouselItem>


            )
        }

    }
}

export default NewSlider;