import React, {Component} from "react";
import Nav from "./NavBar";
import {MDBRow} from "mdbreact";
import Footer from "./footer";
import {useParams} from "react-router";
import {connect} from "react-redux";
import {ToastContainer} from "react-toastify";
import {BrowserView, MobileView} from "react-device-detect";
import {withTranslation} from "react-i18next";
import {LanguageContext} from "../LanguageContext";
import ErrorToGetData from "./ErrorToGetData";


class AboutUs extends Component{

    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            lang: this.props.params.lang,
        }
    }

    chooseLang() {

        if(this.state.lang === 'fa'){
            localStorage.setItem("lang", this.state.lang);
        }else {
            if(this.state.lang === 'en'){
                localStorage.setItem("lang", this.state.lang);
            }else {
                if(localStorage.getItem("lang") === null) {
                    localStorage.setItem("lang", 'en');
                }
            }
        }

        var l = localStorage.getItem("lang")
        this.languageChangeListener = () =>{
            var lang = localStorage.getItem("lang")
            this.getData(lang);
        }

        this.props.i18n.on('languageChanged', this.languageChangeListener);

        if (l !== null) {
            const {setLanguage} = this.context;
            setLanguage(l)

            this.props.i18n.changeLanguage(l);
        }
    }

    componentDidMount() {
        var lang = localStorage.getItem("lang")
        if(lang == null){
            lang = this.props.t.language
        }
        this.chooseLang()

        this.getData(lang);
    }

    getData(lang) {
        fetch('https://uharmonymatch.com/api/v1/about/?lang=' + lang)
            .then(res => res.json())
            .then(json => {
                if (localStorage.getItem("profile") == null) {
                    this.getUserProfile(json)
                } else {
                    this.setState({data: json});
                }

            });
    }

    getUserProfile(data){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('https://uharmonymatch.com/api/v1/profile/', requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {
                localStorage.setItem('profile', JSON.stringify({
                    is_registration_complete: response.data.is_registration_complete,
                    is_seen_videos: response.data.is_seen_videos,
                    is_verified: response.data.is_verified
                }));
                this.props.user_profile({
                    is_registration_complete: response.data.is_registration_complete,
                    is_verified: response.data.is_verified
                })
                this.setState({data: data});

            })
            .catch((error) => {
                if (error === 401) {
                    this.setState({data: data})
                } else {
                    if(error == 403) {
                        this.setState({is_block: true});
                    }else {
                        if (error == 429) {

                        }
                    }
                }

            });
    }

    mobileView(){
        var lang = localStorage.getItem("lang")
        if(lang == null){
            lang = 'fa'
        }
        if(lang === 'en') {
            return (
                <body className="text-right bg-body" dir="rtl">
                <Nav/>

                <MDBRow>
                    <img src={'../images/aboutusimg.png'}/>
                </MDBRow>
                <MDBRow className={'m-3 p-3'}>
                    <div className="text-left ltr"  style={{fontWeight: 'bold', fontSize: '20px'}}>
                        {this.state.data.title}
                    </div>
                </MDBRow>

                <MDBRow className={'m-3 p-3'}>
                    <div className="text-left ltr">
                        <div style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{__html: this.state.data.content}} />

                    </div>
                </MDBRow>

                <MDBRow className={'p-3 text-center align-content-center align-items-center justify-content-center '}>
                    <div className="text-center" style={{width: '90%',}}>
                        <p className="text-center" style={{fontWeight: 'bold', fontSize: '15px'}}>
                            {this.props.t('about_us_more_info')}

                        </p>
                    </div>
                </MDBRow>
                <MDBRow className={'mb-5 text-center align-content-center align-items-center justify-content-center '}>
                    <a target="_blank" href={'https://hedayatcentre.org/'} className={'btn'} style={{width: '70%', background: '#355070', color: 'white', borderRadius: '10px'}}>
                        www.hedayatcentre.org
                    </a>
                </MDBRow>

                <Footer
                    isEnglish={this.props.i18n.language === 'en'}
                />

                </body>
            );
        }else {
            return (
                <body className="text-right bg-body" dir="rtl">
                <Nav/>

                <MDBRow>
                    <img src={'../images/aboutusimg.png'}/>
                </MDBRow>
                <MDBRow className={'m-3 p-3'}>
                    <div className="text-right" style={{fontWeight: 'bold', fontSize: '20px'}}>
                        {this.state.data.title}
                    </div>
                </MDBRow>

                <MDBRow className={'m-3 p-3'}>
                    <div className="text-right">
                        <div style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{__html: this.state.data.content}} />

                    </div>
                </MDBRow>

                <MDBRow className={'p-3 text-center align-content-center align-items-center justify-content-center '}>
                    <div className="text-center" style={{width: '90%',}}>
                        <p className="text-center" style={{fontWeight: 'bold', fontSize: '15px'}}>
                            {this.props.t('about_us_more_info')}

                        </p>
                    </div>
                </MDBRow>
                <MDBRow className={'mb-5 text-center align-content-center align-items-center justify-content-center '}>
                    <a target="_blank" href={'https://hedayatcentre.org/'} className={'btn'} style={{width: '70%', background: '#355070', color: 'white', borderRadius: '10px'}}>
                        www.hedayatcentre.org
                    </a>
                </MDBRow>

                <Footer
                    isEnglish={this.props.i18n.language === 'en'}
                />

                </body>
            );
        }
    }

    desktopView(){
        var lang = localStorage.getItem("lang")
        if(lang == null){
            lang = 'fa'
        }
        if(lang === 'en') {
            return (
                <body className="text-right bg-body" dir="rtl">
                <Nav/>

                <MDBRow>
                    <img src={'../images/aboutusimg.png'}/>
                </MDBRow>
                <MDBRow className={'p-3'}>
                    <div className="text-left ltr" style={{fontWeight: 'bold', fontSize: '20px', paddingLeft: '100px', marginTop: '20px'}}>
                        {this.state.data.title}
                    </div>
                </MDBRow>

                <MDBRow className={'p-3'}>
                    <div className="text-left ltr" style={{paddingLeft: '100px', paddingRight: '100px'}}>
                        <div style={{textAlign: 'justify'}}
                             dangerouslySetInnerHTML={{__html: this.state.data.content}}/>

                    </div>
                </MDBRow>

                <MDBRow className={'text-center align-content-center align-items-center justify-content-center '}>
                    <div className="text-center" style={{width: '40%',}}>
                        <p className="text-center" style={{fontWeight: 'bold', fontSize: '15px'}}>
                            {this.props.t('about_us_more_info')}

                        </p>
                    </div>
                </MDBRow>
                <MDBRow className={'text-center align-content-center align-items-center justify-content-center mb-5'}>
                    <a target="_blank" href={'https://hedayatcentre.org/'} className={'btn'}
                       style={{width: '25%', background: '#355070', color: 'white', borderRadius: '10px'}}>
                        www.hedayatcentre.org
                    </a>
                </MDBRow>

                <Footer
                    isEnglish={this.props.i18n.language === 'en'}
                />

                </body>
            );
        }else{
            return (
                <body className="text-right bg-body" dir="rtl">
                <Nav/>

                <MDBRow>
                    <img src={'../images/aboutusimg.png'}/>
                </MDBRow>
                <MDBRow className={'m-3 p-3'}>
                    <div className="text-right" style={{fontWeight: 'bold', fontSize: '20px', paddingRight: '100px', marginTop: '20px'}}>
                        {this.state.data.title}
                    </div>
                </MDBRow>

                <MDBRow className={'m-2 p-3'}>
                    <div className="text-right">

                        <div style={{textAlign: 'justify', paddingLeft: '100px', paddingRight: '100px'}}
                             dangerouslySetInnerHTML={{__html: this.state.data.content}}/>

                    </div>
                </MDBRow>

                <MDBRow className={' text-center align-content-center align-items-center justify-content-center '}>
                    <div className="text-center" style={{width: '40%',}}>
                        <p className="text-center" style={{fontWeight: 'bold', fontSize: '15px'}}>
                            {this.props.t('about_us_more_info')}

                        </p>
                    </div>
                </MDBRow>
                <MDBRow className={' text-center align-content-center align-items-center justify-content-center mb-5'}>
                    <a target="_blank" href={'https://hedayatcentre.org/'} className={'btn'}
                       style={{width: '25%', background: '#355070', color: 'white', borderRadius: '10px'}}>
                        www.hedayatcentre.org
                    </a>
                </MDBRow>

                <Footer
                    isEnglish={this.props.i18n.language === 'en'}
                />

                </body>
            );
        }
    }

    render() {

        if(this.state.data == null){
            return (
                <div>
                    {/*<ErrorToGetData/>*/}
                </div>
            )
        }
        return (
            <div>
                <ToastContainer/>
                <BrowserView>
                    {this.desktopView()}
                </BrowserView>
                <MobileView>
                    {this.mobileView()}
                </MobileView>
            </div>
        );



    }
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

function  mapState(state){
    return {
        user_profile: state.user_profile
    }
}

const updateProfile =(dispatch)=>{
    return{
        user_profile: (item)=>{ dispatch({type: 'UPDATE_PROFILE', item}) }
    }
}

export default connect(mapState, updateProfile)(withParams(withTranslation()(AboutUs)));