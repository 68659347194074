import React, {Component} from "react";
import Footer from "./footer";
import Nav from "./NavBar";
import {LanguageContext} from "../LanguageContext";
import {BrowserView, MobileView} from "react-device-detect";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useParams} from "react-router";


class Terms extends Component{
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            data: null
        }
    }

    chooseLang(){

        var l = localStorage.getItem("lang")
        this.languageChangeListener = () =>{
            var lang = localStorage.getItem("lang")

        }

        this.props.i18n.on('languageChanged', this.languageChangeListener);

        if (l !== null) {
            const {setLanguage} = this.context;
            setLanguage(l)

            this.props.i18n.changeLanguage(l);
        }
    }

    componentDidMount() {

        this.chooseLang()

        this.getData(this.props.i18n.language)
    }


    getData(lang) {
        fetch('https://uharmonymatch.com/api/v1/terms/?lang=' + lang)
            .then(res => res.json())
            .then(json => {
                if (localStorage.getItem("profile") == null) {
                    this.getUserProfile(json)
                } else {
                    this.setState({data: json});
                }

            });
    }

    getUserProfile(data){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('https://uharmonymatch.com/api/v1/profile/', requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {
                localStorage.setItem('profile', JSON.stringify({
                    is_registration_complete: response.data.is_registration_complete,
                    is_seen_videos: response.data.is_seen_videos,
                    is_verified: response.data.is_verified
                }));
                this.props.user_profile({
                    is_registration_complete: response.data.is_registration_complete,
                    is_verified: response.data.is_verified
                })
                this.setState({data: data});

            })
            .catch((error) => {
                if (error === 401) {
                    this.setState({data: data});
                } else {
                    if(error == 403) {
                        this.setState({is_block: true});
                    }else {
                        if (error == 429) {
                            this.setState({data: data});
                        }
                    }
                }

            });
    }

    desktopView(){
        if(this.state.data === null){
            return (
                <div>
                    Noting
                </div>
            )
        }
        if(this.props.i18n.language === 'fa'){
            return (
                <body className="text-right bg-body" dir="rtl">
                <Nav/>

                <div className="text-right rtl m-3" style={{paddingLeft: '100px', paddingRight: '100px'}}>
                    <div style={{textAlign: 'justify'}}
                         dangerouslySetInnerHTML={{__html: this.state.data.content}}/>
                </div>

                <Footer
                    isEnglish={this.props.i18n.language === 'en'}
                />

                </body>
            );
        }else {
            return (
                <body className="text-left bg-body" dir="ltr">
                <Nav/>

                <div className="text-left ltr m-3" style={{paddingLeft: '100px', paddingRight: '100px'}}>
                    <div style={{textAlign: 'justify'}}
                         dangerouslySetInnerHTML={{__html: this.state.data.content}}/>
                </div>

                <Footer
                    isEnglish={this.props.i18n.language === 'en'}
                />

                </body>
            );
        }
    }

    mobileView(){
        if(this.state.data === null){
            return (
                <div>
                    Noting
                </div>
            )
        }
        if(this.props.i18n.language === 'fa'){
            return (
                <body className="text-right bg-body" dir="rtl">
                <Nav/>

                <div className="text-right rtl m-3" style={{paddingLeft: '30px', paddingRight: '30px'}}>
                    <div style={{textAlign: 'justify'}}
                         dangerouslySetInnerHTML={{__html: this.state.data.content}}/>
                </div>

                <Footer
                    isEnglish={this.props.i18n.language === 'en'}
                />

                </body>
            );
        }
        return (
            <body className="text-left bg-body" dir="ltr">
            <Nav/>

            <div className="text-left ltr m-3" style={{paddingLeft: '30px', paddingRight: '30px'}}>
                <div style={{textAlign: 'justify'}}
                     dangerouslySetInnerHTML={{__html: this.state.data.content}}/>
            </div>

            <Footer
                isEnglish={this.props.i18n.language === 'en'}
            />

            </body>
        );
    }

    render() {

        return (
            <div>
                <BrowserView>
                    {this.desktopView()}
                </BrowserView>
                <MobileView>
                    {this.mobileView()}
                </MobileView>
            </div>
        );

    }
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

function  mapState(state){
    return {
        user_profile: state.user_profile
    }
}

const updateProfile =(dispatch)=>{
    return{
        user_profile: (item)=>{ dispatch({type: 'UPDATE_PROFILE', item}) }
    }
}

export default connect(mapState, updateProfile)(withParams(withTranslation()(Terms)));