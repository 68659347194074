// i18n.js
import i18n from "i18next";

import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import XHR from "i18next-xhr-backend";

i18n
    .use(detector)
    .use(backend)
    .use(initReactI18next).init({
    fallbackLng: 'fa',
    lng: 'fa',
    resources: {
        en: {
            translations: require('./locales/en.json')
        },
        fa: {
            translations: require('./locales/fa.json')
        },
    },
    ns: ['translations'],
    interpolation: {
        escapeValue: false // react already safes from xss
    },
    defaultNS: 'translations'
});
i18n.languages = ['en', 'fa'];

export default i18n;
