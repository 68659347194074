import React, {Component} from "react";
import {MDBCard, MDBCol, MDBRow} from "mdbreact";
import SingleAnswerQuestion from "./SingleAnswerQuestion";
import CheckBoxTypeQuestion from "./CheckBoxTypeQuestion";
import RangeTypeQuestion from "./RangeTypeQuestion";
import {ToastContainer} from "react-toastify";
import {BrowserView, MobileView} from "react-device-detect";
import {withTranslation} from "react-i18next";
import {LanguageContext} from "../LanguageContext";


class QuestionRow extends Component{
    static contextType = LanguageContext;

    constructor(props) {
        super(props);
    }


    chooseLang() {
        var l = localStorage.getItem("lang")
        this.languageChangeListener = () =>{
            var lang = localStorage.getItem("lang")

        }

        this.props.i18n.on('languageChanged', this.languageChangeListener);

        if (l !== null) {
            const {setLanguage} = this.context;
            setLanguage(l)

            this.props.i18n.changeLanguage(l);
        }
    }

    componentDidMount() {
        this.chooseLang()
    }

    desktopView(){
        if(this.props.i18n.language === 'en'){

            return(
                <div>
                    <div className={'m-2 p-3 ltr'}>
                        <MDBRow>

                            <MDBCol className={'d-flex justify-content-start ltr'}>
                                <text className={'text-right ltr'} style={{color: 'black'}}>
                                    {this.props.data.title}
                                </text>
                            </MDBCol>

                            <MDBCol className={'d-flex justify-content-end ltr'}>
                                <label>
                                    {this.props.data.number}
                                    &nbsp; {this.props.i18n.t('from')}
                                    &nbsp; {this.props.data.number_of_categories}
                                </label>

                            </MDBCol>

                        </MDBRow>
                        <MDBRow className={'m-3'}>
                            <MDBCard className={'border'}>
                                {this.props.data.questions.map((item, index) => (
                                    this.getQuestion(index, item)
                                ))}
                            </MDBCard>
                        </MDBRow>
                    </div>
                </div>
            )
        }
        return(
            <div>
                <div className={'m-2 p-3'}>
                    <MDBRow>
                        <MDBCol>
                            <text className={'text-start'} style={{color: 'black', fontFamily: 'IRANSansX'}}>
                                {this.props.data.title}
                            </text>
                        </MDBCol>
                        <MDBCol className={'d-flex justify-content-end'}>
                            <text className={'text-left'} style={{color: 'black', fontFamily: 'IRANSansX'}}>
                                <label>
                                    {new Number(this.props.data.number).toLocaleString('fa-ir')}
                                    &nbsp; {this.props.i18n.t('from')}
                                    &nbsp; {new Number(this.props.data.number_of_categories).toLocaleString('fa-ir')}
                                </label>
                            </text>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className={'m-3'}>
                        <MDBCard className={'border'}>
                            {this.props.data.questions.map((item, index) => (
                                this.getQuestion(index, item)
                            ))}
                        </MDBCard>
                    </MDBRow>
                </div>
            </div>
        )
    }

    mobileView(){
        if(this.props.i18n.language === 'en') {
            return (
                <div className={'m-2 p-3'}>
                    <MDBRow>

                        <text className={'text-start'} style={{color: 'black'}}>
                            {this.props.data.title}
                        </text>
                    </MDBRow>
                    <MDBRow className={'d-flex justify-content-end'}>
                    <span className={'text-end'}
                          style={{color: 'black', fontFamily: 'IRANSansX', whiteSpace: 'nowrap', direction: 'ltr'}}>
                        {this.props.data.number} {this.props.i18n.t('from')} {this.props.data.number_of_categories}
                    </span>
                    </MDBRow>

                    <MDBRow>
                        <MDBCard className={'border'}>
                            {this.props.data.questions.map((item, index) => (
                                this.getQuestion(index, item)
                            ))}
                        </MDBCard>
                    </MDBRow>
                </div>
            )
        }
        return (
            <div className={'m-2 p-3 ltr'}>
                <MDBRow>

                    <text className={'text-end'} style={{color: 'black'}}>
                        {this.props.data.title}
                    </text>
                </MDBRow>
                <MDBRow className={'d-flex justify-content-end'}>
                    <span className={'text-start'}
                          style={{color: 'black', fontFamily: 'IRANSansX', whiteSpace: 'nowrap', direction: 'rtl'}}>
                        {this.props.data.number} {this.props.i18n.t('from')} {this.props.data.number_of_categories}
                    </span>
                </MDBRow>

                <MDBRow>
                    <MDBCard className={'border'}>
                        {this.props.data.questions.map((item, index) => (
                            this.getQuestion(index, item)
                        ))}
                    </MDBCard>
                </MDBRow>
            </div>
        )
    }

    render() {
        return (
            <div style={{fontFamily: 'IRANSansX'}}>
                <ToastContainer/>
                <BrowserView>
                    {this.desktopView()}
                </BrowserView>
                <MobileView>
                    {this.mobileView()}
                </MobileView>
            </div>
        );

    }

    getQuestion(index, item){
        if(this.props.i18n.language === 'en'){
            if (item.type === 1) {
                return (
                    <div className={'ltr text-left'}>
                        <SingleAnswerQuestion
                            dir={'en'}
                            question={item.question}
                            question_id={item.id}
                            answers={item.answers}
                            listener={this.setSingleAnswers}
                        />

                    </div>
                )
            }

            if (item.type === 2) {
                return (
                    <div className={'ltr text-left'}>
                        <CheckBoxTypeQuestion
                            dir={'en'}
                            question={item.question}
                            question_id={item.id}
                            answers={item.answers}
                            listener={this.setCheckBoxQuestionAnswer}
                        />
                    </div>
                )
            }

            if (item.type === 3) {
                return (
                    <div className={'ltr text-left'}>
                        <RangeTypeQuestion
                            dir={'en'}
                            question={item.question}
                            question_id={item.id}
                            answers={item.answers}
                            listener={this.setRangeQuestionAnswers}
                        />
                    </div>
                )
            }
        }else {
            if (item.type === 1) {
                return (
                    <div>
                        <SingleAnswerQuestion
                            dir={'fa'}
                            question={item.question}
                            question_id={item.id}
                            answers={item.answers}
                            listener={this.setSingleAnswers}
                        />

                    </div>
                )
            }

            if (item.type === 2) {
                return (
                    <div>
                        <CheckBoxTypeQuestion
                            dir={'fa'}
                            question={item.question}
                            question_id={item.id}
                            answers={item.answers}
                            listener={this.setCheckBoxQuestionAnswer}
                        />
                    </div>
                )
            }

            if (item.type === 3) {
                return (
                    <div>
                        <RangeTypeQuestion
                            dir={'fa'}
                            question={item.question}
                            question_id={item.id}
                            answers={item.answers}
                            listener={this.setRangeQuestionAnswers}
                        />
                    </div>
                )
            }
        }
    }

    setRangeQuestionAnswers(st, qid, ansid, range){
        var answers = localStorage.getItem("answers")
        var answers_nc = localStorage.getItem("anc")

        if(answers_nc==null){
            answers_nc = []
        }else {
            answers_nc = JSON.parse(answers_nc)
        }
        if(answers==null){
            answers = {}
            answers[qid] = {answer_id: {[ansid]: range}, range: true}
            localStorage.setItem("answers", JSON.stringify(answers))

            answers_nc.push(ansid)
            localStorage.setItem("anc", JSON.stringify(answers_nc))
        }else {
            answers = JSON.parse(answers)
            if(qid in answers){
                answers[qid].answer_id[ansid] = range
                answers[qid].range = true
            }else {
                answers[qid] = {answer_id: {[ansid]: range}, range: true}
            }
            localStorage.setItem("answers", JSON.stringify(answers))

            if (!answers_nc.includes(ansid)) {
                answers_nc.push(ansid)
                localStorage.setItem("anc", JSON.stringify(answers_nc))
            }
        }
    }

    setCheckBoxQuestionAnswer(st, qid, ansid){
        var answers = localStorage.getItem("answers")
        var answers_nc = localStorage.getItem("anc")

        if(answers_nc==null){
            answers_nc = []
        }else {
            answers_nc = JSON.parse(answers_nc)
        }
        if(answers==null){
            answers = {}
            answers[qid] = {answer_id: [ansid], range: false}
            localStorage.setItem("answers", JSON.stringify(answers))

            answers_nc.push(ansid)
            localStorage.setItem("anc", JSON.stringify(answers_nc))
        }else {
            answers = JSON.parse(answers)
            if(qid in answers){
                if(answers[qid].answer_id.includes(ansid)){
                    var index = answers[qid].answer_id.indexOf(ansid)
                    answers[qid].answer_id.splice(index, 1)
                }else {
                    answers[qid].answer_id.push(ansid)
                }
            }else {
                answers[qid] = {answer_id: [ansid], range: false}
            }
            localStorage.setItem("answers", JSON.stringify(answers))

            if (!answers_nc.includes(ansid)) {
                answers_nc.push(ansid)
                localStorage.setItem("anc", JSON.stringify(answers_nc))
            }
        }
    }

    setSingleAnswers(st, qid, ansid){
        var answers = localStorage.getItem("answers")
        var answers_nc = localStorage.getItem("anc")

        if(answers_nc==null){
            answers_nc = []
        }else {
            answers_nc = JSON.parse(answers_nc)
        }

        if(answers==null){
            answers = {}
            answers[qid] = {answer_id: [ansid], range: false}
            localStorage.setItem("answers", JSON.stringify(answers))

            answers_nc.push(ansid)
            localStorage.setItem("anc", JSON.stringify(answers_nc))
        }else {
            answers = JSON.parse(answers)
            answers[qid] = {answer_id: [ansid], range: false}
            localStorage.setItem("answers", JSON.stringify(answers))

            if (!answers_nc.includes(ansid)) {
                answers_nc.push(ansid)
                localStorage.setItem("anc", JSON.stringify(answers_nc))
            }
        }
    }
}

export default withTranslation()(QuestionRow);