import React, {Component} from "react";
import {MDBRow} from "mdbreact";
import {Button} from "@mui/material";
import Footer from "./footer";
import {withTranslation} from "react-i18next";
import {LanguageContext} from "../LanguageContext";
import RightSideNavbar from "./rightSideNavbar";
import {ToastContainer} from "react-toastify";
import {BrowserView, MobileView} from "react-device-detect";
import {
    MDBCollapse,
    MDBIcon,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarItem, MDBNavbarLink,
    MDBNavbarNav,
    MDBNavbarToggler
} from "mdb-react-ui-kit";


class NoUsersFound extends Component{

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            lang: localStorage.getItem("lang")
        }
    }

    chooseLang() {

        if(this.state.lang === 'fa'){
            localStorage.setItem("lang", this.state.lang);
        }else {
            if(this.state.lang === 'en'){
                localStorage.setItem("lang", this.state.lang);
            }else {
                if(localStorage.getItem("lang") === null) {
                    localStorage.setItem("lang", 'en');
                }
            }
        }

        var l = localStorage.getItem("lang")
        this.languageChangeListener = () =>{
            var lang = localStorage.getItem("lang")

        }

        this.props.i18n.on('languageChanged', this.languageChangeListener);

        if (l !== null) {
            const {setLanguage} = this.context;
            setLanguage(l)

            this.props.i18n.changeLanguage(l);
        }
    }

    componentDidMount() {
        this.chooseLang()
    }

    desktopView() {
        if (this.props.isEn) {
            return (
                <body className="text-right bg-body ltr" dir="ltr">

                <div className={'container-fluid'}>
                    <RightSideNavbar
                        profile={this.props.profile}
                        page={'candidate-list'}
                    />
                    <div style={{marginLeft: '240px', padding: '0px 10px', marginTop: '300px', fontWeight: 'bold'}}
                         className={'text-center'}>

                        {this.props.i18n.t('cannot_find_users_according_to_your_answers')}

                    </div>
                </div>
                </body>
            );
        } else {
            return (
                <body className="text-right bg-body" dir="rtl">
                <div className={'container-fluid'}>
                    <RightSideNavbar
                        profile={this.props.profile}
                        page={'candidate-list'}
                    />
                    <div style={{marginRight: '300px', padding: '0px 10px', marginTop: '350px', fontWeight: 'bold'}}
                         className={'text-center'}>

                        {this.props.i18n.t('cannot_find_users_according_to_your_answers')}

                    </div>
                </div>
                </body>
            );
        }
    }

    mobileView() {
        const { setLanguage } = this.context;

        const changeLangBTN = (newLanguage) => {
            localStorage.setItem("lang", newLanguage);
            setLanguage(newLanguage);
            window.location.reload()
        };

        if (this.props.isEn) {
            return (
                <body className="text-right bg-body ltr" dir="ltr">

                <MDBNavbar expand='lg' light bgColor='light' className={'p-3 mb-3 text-left'}
                           style={{direction: 'ltr'}}>

                    <MDBNavbarBrand href='/'>
                        <img
                            src='/logo192.png'
                            height='30'
                            alt='کانون هدایت'
                            loading='lazy'
                        />
                    </MDBNavbarBrand>

                    <MDBNavbarToggler
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => this.setState({open: !this.state.open})}
                    >
                        <MDBIcon icon='bars' fas/>
                    </MDBNavbarToggler>

                    <MDBCollapse navbar show={this.state.open}>

                        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-end'>
                            <MDBNavbarItem>
                                <MDBNavbarLink  href='/my-profile'
                                               style={{fontWeight: 'bold'}}>
                                    {this.props.i18n.t('my_profile')}
                                </MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink href='/learning'
                                               style={{fontWeight: 'bold'}}>{this.props.i18n.t('learning')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink active aria-current='page' href='/users'
                                               style={{fontWeight: 'bold'}}>{this.props.i18n.t('users_list')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/'
                                               style={{fontWeight: 'bold'}}>{this.props.i18n.t('home_page_short')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/logout'
                                               style={{fontWeight: 'bold'}}>{this.props.i18n.t('sign_out')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <div className={'mt-3'}>
                                <MDBIcon flag='uk' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('en')}/>
                                <MDBIcon flag='ir' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('fa')}/>
                            </div>

                        </MDBNavbarNav>


                    </MDBCollapse>

                </MDBNavbar>

                <MDBRow className={'mt-5'}></MDBRow>
                <MDBRow className={'mt-5'}></MDBRow>
                <MDBRow className={'mt-5'}></MDBRow>
                <MDBRow className={'text-center m-3 mt-5'} style={{fontWeight: 'bold'}}>
                    {this.props.i18n.t('cannot_find_users_according_to_your_answers')}
                </MDBRow>
                </body>
        )
        ;
        } else {
            return (
                <body className="text-right bg-body" dir="rtl">

                <MDBNavbar expand='lg' light bgColor='light' className={'p-3 mb-3'} style={{direction: 'ltr'}}>

                    <MDBNavbarBrand href='/'>
                        <img
                            src='/logo192.png'
                            height='30'
                            alt='کانون هدایت'
                            loading='lazy'
                        />
                    </MDBNavbarBrand>

                    <MDBNavbarToggler
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => this.setState({open: !this.state.open})}
                    >
                        <MDBIcon icon='bars' fas/>
                    </MDBNavbarToggler>

                    <MDBCollapse navbar show={this.state.open}>

                        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-end'>
                            <MDBNavbarItem>
                                <MDBNavbarLink  href='/my-profile'
                                               style={{fontWeight: 'bold'}}>
                                    {this.props.i18n.t('my_profile')}
                                </MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink href='/learning'
                                               style={{fontWeight: 'bold'}}>{this.props.i18n.t('learning')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink active aria-current='page' href='/users'
                                               style={{fontWeight: 'bold'}}>{this.props.i18n.t('users_list')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/'
                                               style={{fontWeight: 'bold'}}>{this.props.i18n.t('home_page_short')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/logout'
                                               style={{fontWeight: 'bold'}}>{this.props.i18n.t('sign_out')}</MDBNavbarLink>
                            </MDBNavbarItem>
                        </MDBNavbarNav>

                        <div className={'mt-3'}>
                            <MDBIcon flag='uk' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('en')}/>
                            <MDBIcon flag='ir' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('fa')}/>
                        </div>

                    </MDBCollapse>

                </MDBNavbar>

                <MDBRow className={'mt-5'}></MDBRow>
                <MDBRow className={'mt-5'}></MDBRow>
                <MDBRow className={'mt-5'}></MDBRow>
                <MDBRow className={'text-center m-3 mt-5'} style={{fontWeight: 'bold'}}>
                        {this.props.i18n.t('cannot_find_users_according_to_your_answers')}
                </MDBRow>

                </body>
            );
        }
    }

    render() {
        return(
            <div>
                <BrowserView>
                    {this.desktopView()}
                </BrowserView>
                <MobileView>
                    {this.mobileView()}
                </MobileView>
            </div>
        );

    }

}

export default withTranslation()(NoUsersFound);