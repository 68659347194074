import {MDBCol, MDBRow} from "mdbreact";
import React from "react";
import {ToastContainer} from "react-toastify";
import {BrowserView, MobileView} from "react-device-detect";


class RangeTypeQuestion extends React.Component{

    constructor(props) {
        super(props);
    }

    mobileView(){
        if(this.props.dir === 'fa') {

            return(
                <div className={'p-3'} style={{color: 'black', fontWeight: "bold"}}>
                    <MDBRow>
                        <div>
                            <label className={'m-4'} style={{fontWeight: "bold"}}>
                                {this.props.question}
                            </label>
                        </div>
                    </MDBRow>
                    <MDBRow>
                        <div className={'p-2 justify-content-end align-items-end text-end'}>

                            {this.props.answers.map((item, index) => (
                                this.getAnswerItem(item, index, true, false)
                            ))}

                        </div>
                    </MDBRow>
                </div>
            )
        }else {
            return(
                <div className={'p-3 ltr'} style={{color: 'black', fontWeight: "bold"}}>
                    <MDBRow>
                        <div className={'text-start'}>
                            <label className={'m-1 ltr text-start'} style={{fontWeight: "bold", textAlign: "justify"}}>
                                {this.props.question}
                            </label>
                        </div>
                    </MDBRow>
                    <MDBRow>
                        <div className={'p-2 justify-content-start align-items-start text-start'}>

                            {this.props.answers.map((item, index) => (
                                this.getAnswerItem(item, index, true, true)
                            ))}

                        </div>
                    </MDBRow>
                </div>
            )
        }
    }

    desktopView(){
        if(this.props.dir === 'fa') {

            return(
                <div className={'p-3'} style={{color: 'black', fontWeight: "bold"}}>
                    <MDBRow>
                        <div>
                            <label className={'m-4'} style={{fontWeight: "bold"}}>
                                {this.props.question}
                            </label>
                        </div>
                    </MDBRow>
                    <MDBRow>
                        {/*<div className={'p-2 justify-content-end align-items-end text-end'} style={{marginRight: '30px'}}>*/}

                            {this.props.answers.map((item, index) => (
                                this.getAnswerItem(item, index, false, false)
                            ))}

                        {/*</div>*/}
                    </MDBRow>
                </div>
            )
        }else {
            return(
                <div className={'p-3'} style={{color: 'black', fontWeight: "bold"}}>
                    <MDBRow>
                        <div className={'text-start'}>
                            <label className={'m-4 text-start'} style={{fontWeight: "bold"}}>
                                {this.props.question}
                            </label>
                        </div>
                    </MDBRow>
                    <MDBRow>
                        {/*<div className={'p-2 justify-content-start align-items-start text-start ltr'} style={{marginLeft: '30px'}}>*/}

                            {this.props.answers.map((item, index) => (
                                this.getAnswerItem(item, index, false, true)
                            ))}

                        {/*</div>*/}
                    </MDBRow>
                </div>
            )
        }
    }
    render() {
        return (
            <div>
                <ToastContainer/>
                <BrowserView>
                    {this.desktopView()}
                </BrowserView>
                <MobileView>
                    {this.mobileView()}
                </MobileView>
            </div>

        )
    }

    getAnswerItem(item, index, isMobile, isEN){
        var lst = [
            <option className={'text-center'} selected
                    disabled
                    value={item.title}>1-{item.range}</option>
        ]
        {Array(item.range).fill().map(function (v, i) {
            lst.push(
                <option className={'text-center'} value={i+1} id={item.id}>{i+1}</option>
            )

        })}

        if(isMobile){
            if(isEN){
                return(
                    <div className={'text-left mb-1 ltr'}>
                        <div className={'text-left ltr'}>
                            <text className={'text-left'} style={{fontSize: "11px", direction: 'ltr', textAlign: 'justify', fontWeight: 'bold', fontFamily: 'IRANSansX'}}>
                                {item.title}
                            </text>
                        </div>
                        <select
                            required
                            onChange={e => this.getData(e)}
                            className={'form-control text-left ltr'}
                            style={{display:'inline-block', width: '100%'}}
                            name="selectedFruit">
                            {lst}
                        </select>
                    </div>
                );
            }
            return(
                <div className={'text-center mb-1'}>
                    <div className={'text-center'}>
                        <label className={' text-center'} style={{fontSize: "13px"}}>
                            {item.title}
                        </label>
                    </div>
                    <select
                        required
                        onChange={e => this.getData(e)}
                        className={'form-control text-center'}
                        style={{display:'inline-block', width: '100%'}}
                        name="selectedFruit">
                        {lst}
                    </select>
                </div>
            );
        }

        if(isEN){
            return(
                <MDBCol size={"4"} className={'text-left'}>
                    <div style={{fontSize: "13px", textAlign: 'left', fontWeight: 'bold', fontFamily: 'IRANSansX', direction: 'rtl'}}>
                            {item.title}
                    </div>
                    <select
                        required
                        onChange={e => this.getData(e)}
                        className={'w-75 form-control m-3'}
                        style={{display:'inline-block', width: 'auto'}}
                        name="selectedFruit">
                        {lst}
                    </select>
                </MDBCol>
            );
        }
        return(
            <MDBCol size={"4"}>
                <div>
                    <label className={'m-1'} style={{fontSize: "13px"}}>
                        {item.title}
                    </label>
                </div>
                <select
                    required
                    onChange={e => this.getData(e)}
                    className={'w-75 form-control m-3'}
                    style={{display:'inline-block', width: 'auto'}}
                    name="selectedFruit">
                    {lst}
                </select>
            </MDBCol>
        );
    }

    getData(e){
        this.props.listener(e, this.props.question_id, e.target.options[e.target.selectedIndex].id, e.target.value);
    }
}

export default RangeTypeQuestion;