import React, {Component} from "react";

class CheckboxOption extends Component {
    render() {
        const { innerProps, label, isSelected } = this.props;

        return (
            <div {...innerProps} className={'m-4'}>
                <input type="checkbox" checked={isSelected} onChange={() => {}} />
                <span style={{ marginLeft: '8px' }}>{label}</span>
            </div>
        );
    }
}

export default CheckboxOption;