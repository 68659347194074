import React, {Component} from "react";
import {
    MDBCollapse,
    MDBIcon,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbarNav,
    MDBNavbarToggler
} from "mdb-react-ui-kit";
import IntroPage from "./IntroPage";
import {MDBRow} from "mdbreact";
import {FaTelegramPlane} from "react-icons/fa";
import {toast, ToastContainer} from "react-toastify";
import {BrowserView, MobileView} from "react-device-detect";
import QuestionRow from "./QuestionRow";
import {useNavigate, useParams} from "react-router";
import {connect} from "react-redux";
import Swal from "sweetalert2";
import RightSideNavbar from "./rightSideNavbar";
import {withTranslation} from "react-i18next";
import {LanguageContext} from "../LanguageContext";


class SurveyPage extends Component{

    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            profile: null,
            isIntro: localStorage.getItem('intro'),
            data: null,
            submit_status: 0,
            lang: this.props.params.lang,

        }

        localStorage.removeItem('answers')
    }

    chooseLang() {
        if(this.state.lang === 'fa'){
            localStorage.setItem("lang", this.state.lang);
        }else {
            if(this.state.lang === 'en'){
                localStorage.setItem("lang", this.state.lang);
            }else {
                if(localStorage.getItem("lang") === null) {
                    localStorage.setItem("lang", 'en');
                }
            }
        }

        var l = localStorage.getItem("lang")
        this.languageChangeListener = () =>{
            var lang = localStorage.getItem("lang")

        }

        this.props.i18n.on('languageChanged', this.languageChangeListener);

        if (l !== null) {
            const {setLanguage} = this.context;
            setLanguage(l)

            this.props.i18n.changeLanguage(l);
        }
    }

    componentDidMount() {
        this.chooseLang()
        this.getUserProfile()
        if(!this.props.user_profile.is_registration_complete) {
            this.getQuestions()
        }
    }

    getQuestions(){
        localStorage.removeItem('answers')
        localStorage.removeItem('anc')

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            }
        };
        var lang = localStorage.getItem("lang")
        if(lang == null){
            lang = 'fa'
        }
        fetch('https://uharmonymatch.com/api/v1/questions-list/?lang='+lang, requestOptions)
            .then(res => res.json())
            .then((response) => {
                this.setState({data: response});

            });
    }

    getUserProfile(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('https://uharmonymatch.com/api/v1/profile/', requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {
                if(response.status === 200) {
                    localStorage.setItem('profile', JSON.stringify({
                        is_registration_complete: response.data.is_registration_complete,
                        is_seen_videos: response.data.is_seen_videos,
                        is_verified: response.data.is_verified
                    }));
                    this.setState({
                        profile: response.data
                    });
                }

            })
            .catch((error) => {


            });
    }

    mobileView(){

        const { setLanguage } = this.context;

        const changeLangBTN = (newLanguage) => {
            localStorage.setItem("lang", newLanguage);
            setLanguage(newLanguage);
            window.location.reload()
        };

        if(this.state.isIntro == null || this.state.isIntro == false){
            return (
                <body className="text-right bg-body" dir="rtl" style = {{height:"100vh", fontFamily: 'IRANSansX'}}>
                <div className={'container-fluid'}>
                    <MDBNavbar expand='lg' light bgColor='light' className={'p-3'} style={{direction: 'ltr'}}>

                        <MDBNavbarBrand href='/'>
                            <img
                                src='/logo192.png'
                                height='30'
                                alt='کانون هدایت'
                                loading='lazy'
                            />
                        </MDBNavbarBrand>

                        <MDBNavbarToggler
                            aria-controls='navbarSupportedContent'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            onClick={() => this.setState({open: !this.state.open})}
                        >
                            <MDBIcon icon='bars' fas />
                        </MDBNavbarToggler>

                        <MDBCollapse navbar show={this.state.open}>

                            <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-end'>
                                <MDBNavbarItem>
                                    <MDBNavbarLink active aria-current='page' href='/my-profile' style={{fontWeight: 'bold'}}>
                                        {this.props.i18n.t('my_profile')}
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBNavbarLink href='/learning' style={{fontWeight: 'bold'}}>{this.props.i18n.t('learning')}</MDBNavbarLink>
                                </MDBNavbarItem>

                                <MDBNavbarItem>
                                    <MDBNavbarLink href='/users' style={{fontWeight: 'bold'}}>{this.props.i18n.t('users_list')}</MDBNavbarLink>
                                </MDBNavbarItem>

                                <MDBNavbarItem>
                                    <MDBNavbarLink href='/' style={{fontWeight: 'bold'}}>{this.props.i18n.t('home_page_short')}</MDBNavbarLink>
                                </MDBNavbarItem>

                                <MDBNavbarItem>
                                    <MDBNavbarLink href='/logout' style={{fontWeight: 'bold'}}>{this.props.i18n.t('sign_out')}</MDBNavbarLink>
                                </MDBNavbarItem>

                                <div className={'mt-3'}>
                                    <MDBIcon flag='uk' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('en')}/>
                                    <MDBIcon flag='ir' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('fa')}/>
                                </div>

                            </MDBNavbarNav>


                        </MDBCollapse>

                    </MDBNavbar>

                    <IntroPage/>

                </div>
                </body>
            )
        }

        if(this.state.submit_status === 202) {
            return (
                <body className="text-right bg-body" dir="rtl">
                <div className={'container-fluid'}>

                    <MDBRow className="mt-5 pt-4"></MDBRow>
                    <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center'}>
                        <div>
                            <img width={200} className={'img-fluid'} src="../images/success-reg.png" />
                        </div>
                    </MDBRow>
                    <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center mt-3'}>
                        <div>
                            <p>
                                {this.props.i18n.t('answering_question_completed_msg')}
                            </p>
                        </div>
                        <div>
                            <p>
                                {this.props.i18n.t('answering_question_completed_msg2')}
                            </p>
                        </div>
                    </MDBRow>
                    <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center'}>
                        <div>
                            <a href='/learning' className="btn w-20 mt-3" style={{backgroundColor: '#C4C4C4', color: '#fff'}}>
                                {this.props.i18n.t('back_btn')}
                            </a>
                        </div>
                    </MDBRow>
                    <MDBRow className="mt-5 pt-4"></MDBRow>
                </div>
                </body>
            );
        }

        return(
            <body className="text-right bg-body" dir="rtl" style={{fontFamily: 'IRANSansX'}}>

            <MDBNavbar expand='lg' light bgColor='light' className={'p-3 mb-3'} style={{direction: 'ltr'}}>

                <MDBNavbarBrand href='/'>
                    <img
                        src='/logo192.png'
                        height='30'
                        alt='کانون هدایت'
                        loading='lazy'
                    />
                </MDBNavbarBrand>

                <MDBNavbarToggler
                    aria-controls='navbarSupportedContent'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                    onClick={() => this.setState({open: !this.state.open})}
                >
                    <MDBIcon icon='bars' fas />
                </MDBNavbarToggler>

                <MDBCollapse navbar show={this.state.open}>

                    <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-end'>
                        <MDBNavbarItem>
                            <MDBNavbarLink active aria-current='page' href='/my-profile' style={{fontWeight: 'bold'}}>
                                {this.props.i18n.t('my_profile')}
                            </MDBNavbarLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <MDBNavbarLink href='/learning' style={{fontWeight: 'bold'}}>{this.props.i18n.t('learning')}</MDBNavbarLink>
                        </MDBNavbarItem>

                        <MDBNavbarItem>
                            <MDBNavbarLink href='/users' style={{fontWeight: 'bold'}}>{this.props.i18n.t('users_list')}</MDBNavbarLink>
                        </MDBNavbarItem>

                        <MDBNavbarItem>
                            <MDBNavbarLink href='/' style={{fontWeight: 'bold'}}>{this.props.i18n.t('home_page_short')}</MDBNavbarLink>
                        </MDBNavbarItem>

                        <MDBNavbarItem>
                            <MDBNavbarLink href='/logout' style={{fontWeight: 'bold'}}>{this.props.i18n.t('sign_out')}</MDBNavbarLink>
                        </MDBNavbarItem>

                        <div className={'mt-3'}>
                            <MDBIcon flag='uk' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('en')}/>
                            <MDBIcon flag='ir' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('fa')}/>
                        </div>

                    </MDBNavbarNav>


                </MDBCollapse>

            </MDBNavbar>


            <MDBRow className={'m-0'} style={{
                minHeight: '8%',
                backgroundColor: '#355070'}}>

                <div className={'mt-5 mb-5 text-center'}>
                    <text style={{color: 'white', fontWeight: 'bold', fontSize: '18px'}}>
                        {this.props.i18n.t('welcome_to_questions')}
                    </text>
                    <br/>
                    <div>
                        <text style={{color: 'white', fontSize: '15px'}}>
                            {this.props.i18n.t('welcome_to_questions2')}
                        </text>
                    </div>
                </div>
            </MDBRow>

            <MDBRow className={'bg-white p-0 m-0'}>
                {this.state.data.questions.map((item, index) => (
                    <QuestionRow
                        data={item}
                    />
                ))}


            </MDBRow>

            <MDBRow style={{padding: '10px', marginLeft: '30px', marginBottom: '30px'}}>
                <div className={'text-start'}>
                    <button style={{backgroundColor: '#355070', color: 'white'}} className="btn w-20" onClick={(v) => this.submitAnswers(v)}>
                        <FaTelegramPlane style={{marginLeft: '5px'}}/><label style={{fontWeight: "bold", fontSize: "12px"}}>{this.props.i18n.t('contact_us_form_send_btn')}</label>
                    </button>

                </div>

            </MDBRow>


            </body>
        )
    }

    desktopView(){
        if(this.props.i18n.language=== 'en') {
            if (this.props.user_profile.is_registration_complete) {
                return (
                    <body className="text-right bg-body" dir="rtl">
                    <div className={'container-fluid'}>

                        <MDBRow className="mt-5 pt-4"></MDBRow>
                        <MDBRow
                            className={'text-center align-items-center align-self-center justify-content-md-center'}>
                            <div>
                                {/*<img width={200} className={'img-fluid'} src="../images/success-reg.png" />*/}
                            </div>
                        </MDBRow>
                        <MDBRow
                            className={'text-center align-items-center align-self-center justify-content-md-center mt-3'}>
                            <div>
                                <p>
                                    {this.props.i18n.t('you_already_answered')}
                                </p>
                            </div>
                            <div>
                                <p>

                                </p>
                            </div>
                        </MDBRow>
                        <MDBRow
                            className={'text-center align-items-center align-self-center justify-content-md-center'}>
                            <div>
                                <a href='/learning' className="btn w-20 mt-3"
                                   style={{backgroundColor: '#C4C4C4', color: '#fff'}}>
                                    {this.props.i18n.t('back_btn')}
                                </a>
                            </div>
                        </MDBRow>
                        <MDBRow className="mt-5 pt-4"></MDBRow>
                    </div>
                    </body>
                );
            }
            if (this.state.submit_status === 202) {
                return (
                    <body className="text-right bg-body" dir="rtl">
                    <div className={'container-fluid'}>

                        <MDBRow className="mt-5 pt-4"></MDBRow>
                        <MDBRow
                            className={'text-center align-items-center align-self-center justify-content-md-center'}>
                            <div>
                                <img width={200} className={'img-fluid'} src="../images/success-reg.png"/>
                            </div>
                        </MDBRow>
                        <MDBRow
                            className={'text-center align-items-center align-self-center justify-content-md-center mt-3'}>
                            <div>
                                <p>
                                    {this.props.i18n.t('answering_question_completed_msg')}
                                </p>
                            </div>
                            <div>
                                <p>
                                    {this.props.i18n.t('answering_question_completed_msg2')}
                                </p>
                            </div>
                        </MDBRow>
                        <MDBRow
                            className={'text-center align-items-center align-self-center justify-content-md-center'}>
                            <div>
                                <a href='/learning' className="btn w-20 mt-3"
                                   style={{backgroundColor: '#C4C4C4', color: '#fff'}}>
                                    {this.props.i18n.t('back_btn')}
                                </a>
                            </div>
                        </MDBRow>
                        <MDBRow className="mt-5 pt-4"></MDBRow>
                    </div>
                    </body>
                );
            }
            if (this.state.isIntro == null || this.state.isIntro == false) {
                return (
                    <body className="text-left bg-body ltr" dir="rtl" style={{height: "100vh", backgroundColor: '#355070'}}>
                    <div className={'container-fluid'}>
                        <RightSideNavbar
                            profile={this.state.profile}
                            page={'my-profile'}
                        />
                        <div style={{marginLeft: '250px', padding: '0px 10px',  backgroundColor: '#355070'}}>
                        <IntroPage/>
                        </div>

                    </div>
                    </body>
                )
            }
            return (
                <body className="text-right bg-body ltr" dir="ltr" style={{height: "100vh"}}>
                <div className={'container-fluid'}>
                    <RightSideNavbar
                        profile={this.state.profile}
                        page={'my-profile'}
                    />
                    <div style={{marginLeft: '240px', padding: '0px 10px', fontFamily: 'IRANSansX',}}>

                        <MDBRow className={'m-0'} style={{
                            minHeight: '8%',
                            backgroundColor: '#355070'
                        }}>

                            <div className={'mt-5 mb-5 text-center'}>
                                <text style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '20px',
                                    fontFamily: 'IRANSansX'
                                }}>
                                    {this.props.i18n.t('welcome_to_questions')}
                                </text>
                                <br/>
                                <div style={{marginLeft: '80px', marginRight: '80px'}}>
                                    <text style={{color: 'white', fontSize: '20px', fontFamily: 'IRANSansX'}}>
                                        {this.props.i18n.t('welcome_to_questions2')}
                                    </text>
                                </div>
                            </div>
                        </MDBRow>
                        <MDBRow className={'bg-white p-0 m-0'}>
                            {this.state.data.questions.map((item, index) => (
                                <QuestionRow
                                    data={item}
                                    ac={this.state.data.ac}
                                />
                            ))}

                        </MDBRow>
                        <MDBRow  className={'mb-4'} style={{padding: '10px', marginRight: '30px', marginBottom: '30px'}}>
                            <div className={'text-end mb-4'}>
                                <button style={{backgroundColor: '#355070', color: 'white', fontFamily: 'IRANSansX'}} className="btn w-20"
                                        onClick={(v) => this.submitAnswers(v)}>
                                    <label style={{
                                    fontWeight: "bold",
                                    fontSize: "15px"
                                }}>{this.props.i18n.t('contact_us_form_send_btn')}</label><FaTelegramPlane style={{marginLeft: '5px'}}/>
                                </button>

                            </div>

                        </MDBRow>
                    </div>
                </div>
                </body>
            )
        }else {
            if (this.props.user_profile.is_registration_complete) {
                return (
                    <body className="text-right bg-body" dir="rtl">
                    <div className={'container-fluid'}>

                        <MDBRow className="mt-5 pt-4"></MDBRow>
                        <MDBRow
                            className={'text-center align-items-center align-self-center justify-content-md-center'}>
                            <div>
                                {/*<img width={200} className={'img-fluid'} src="../images/success-reg.png" />*/}
                            </div>
                        </MDBRow>
                        <MDBRow
                            className={'text-center align-items-center align-self-center justify-content-md-center mt-3'}>
                            <div>
                                <p>
                                    {this.props.i18n.t('you_already_answered')}
                                </p>
                            </div>
                            <div>
                                <p>

                                </p>
                            </div>
                        </MDBRow>
                        <MDBRow
                            className={'text-center align-items-center align-self-center justify-content-md-center'}>
                            <div>
                                <a href='/learning' className="btn w-20 mt-3"
                                   style={{backgroundColor: '#C4C4C4', color: '#fff'}}>
                                    {this.props.i18n.t('back_btn')}
                                </a>
                            </div>
                        </MDBRow>
                        <MDBRow className="mt-5 pt-4"></MDBRow>
                    </div>
                    </body>
                );
            }
            if (this.state.submit_status === 202) {
                return (
                    <body className="text-right bg-body" dir="rtl">
                    <div className={'container-fluid'}>

                        <MDBRow className="mt-5 pt-4"></MDBRow>
                        <MDBRow
                            className={'text-center align-items-center align-self-center justify-content-md-center'}>
                            <div>
                                <img width={200} className={'img-fluid'} src="../images/success-reg.png"/>
                            </div>
                        </MDBRow>
                        <MDBRow
                            className={'text-center align-items-center align-self-center justify-content-md-center mt-3'}>
                            <div>
                                <p>
                                    {this.props.i18n.t('answering_question_completed_msg')}
                                </p>
                            </div>
                            <div>
                                <p>
                                    {this.props.i18n.t('answering_question_completed_msg2')}
                                </p>
                            </div>
                        </MDBRow>
                        <MDBRow
                            className={'text-center align-items-center align-self-center justify-content-md-center'}>
                            <div>
                                <a href='/learning' className="btn w-20 mt-3"
                                   style={{backgroundColor: '#C4C4C4', color: '#fff'}}>
                                    {this.props.i18n.t('back_btn')}
                                </a>
                            </div>
                        </MDBRow>
                        <MDBRow className="mt-5 pt-4"></MDBRow>
                    </div>
                    </body>
                );
            }
            if (this.state.isIntro == null || this.state.isIntro == false) {
                return (
                    <body className="text-right bg-body" dir="rtl" style={{height: "100vh"}}>


                    <div className={'container-fluid'}>
                        <RightSideNavbar
                            profile={this.state.profile}
                            page={'my-profile'}
                        />

                        <IntroPage/>

                    </div>
                    </body>
                )
            }
            return (
                <body className="text-right bg-body" dir="rtl" style={{height: "100vh"}}>
                <div className={'container-fluid'}>
                    <RightSideNavbar
                        profile={this.state.profile}
                        page={'my-profile'}
                    />
                    <div style={{marginRight: '240px', padding: '0px 10px', fontFamily: 'IRANSansX',}}>

                        <MDBRow className={'m-0'} style={{
                            minHeight: '8%',
                            backgroundColor: '#355070'
                        }}>

                            <div className={'mt-5 mb-5 text-center'}>
                                <text style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '20px',
                                    fontFamily: 'IRANSansX'
                                }}>
                                    {this.props.i18n.t('welcome_to_questions')}
                                </text>
                                <br/>
                                <div style={{marginLeft: '80px', marginRight: '80px'}}>
                                    <text style={{color: 'white', fontSize: '20px', fontFamily: 'IRANSansX'}}>
                                        {this.props.i18n.t('welcome_to_questions2')}
                                    </text>
                                </div>
                            </div>
                        </MDBRow>
                        <MDBRow className={'bg-white p-0 m-0'}>
                            {this.state.data.questions.map((item, index) => (
                                <QuestionRow
                                    data={item}
                                    ac={this.state.data.ac}
                                />
                            ))}

                        </MDBRow>
                        <MDBRow style={{padding: '10px', marginLeft: '30px', marginBottom: '30px'}}>
                            <div className={'text-start mb-5'}>
                                <button style={{backgroundColor: '#355070', color: 'white', fontFamily: 'IRANSansX'}} className="btn w-20"
                                        onClick={(v) => this.submitAnswers(v)}>
                                    <FaTelegramPlane style={{marginLeft: '5px'}}/><label style={{
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                    fontFamily: 'IRANSansX'
                                }}>{this.props.i18n.t('contact_us_form_send_btn')}</label>
                                </button>

                            </div>

                        </MDBRow>
                    </div>
                </div>
                </body>
            )
        }
    }

    render() {
        if(localStorage.getItem("token") === null){
            return(
                <div style={{fontWeight: 'bold', fontSize: '25px', direction: 'ltr'}}>
                    Forbidden!
                </div>
            );
        }

        if(this.state.profile !== null && this.state.data !== null) {
            return (
                <div>
                    <ToastContainer/>
                    <BrowserView>
                        {this.desktopView()}
                    </BrowserView>
                    <MobileView>
                        {this.mobileView()}
                    </MobileView>
                </div>
            );
        }else {

            return (
                <body className="text-right bg-body" dir="rtl">
                {/*<div className={'container-fluid'}>*/}

                {/*    <MDBRow className="mt-5 pt-4"></MDBRow>*/}
                {/*    <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center'}>*/}
                {/*        <div>*/}
                {/*            /!*<img width={200} className={'img-fluid'} src="../images/success-reg.png" />*!/*/}
                {/*        </div>*/}
                {/*    </MDBRow>*/}
                {/*    <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center mt-3'}>*/}
                {/*        <div>*/}
                {/*            <p>*/}
                {/*               /!*{this.props.i18n.t('you_dont_have_perm_to_see')}*!/*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*        <div>*/}
                {/*            <p>*/}

                {/*            </p>*/}
                {/*        </div>*/}
                {/*    </MDBRow>*/}
                {/*    <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center'}>*/}
                {/*        <div>*/}
                {/*            <a href='/learning' className="btn w-20 mt-3" style={{backgroundColor: '#C4C4C4', color: '#fff'}}>*/}
                {/*                {this.props.i18n.t('back_btn')}*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </MDBRow>*/}
                {/*    <MDBRow className="mt-5 pt-4"></MDBRow>*/}
                {/*</div>*/}
                </body>
            );
        }

    }

    submitAnswers(st){
        var answers = localStorage.getItem("answers")
        var answers_nc = localStorage.getItem("anc")
        if(answers_nc==null){
            answers_nc = []
        }else {
            answers_nc = JSON.parse(answers_nc)
        }

        if(answers == null){
            var txt = this.props.i18n.t('answer_all_questions_toast')
            toast.error(txt, {
                position: toast.POSITION.TOP_LEFT
            });
        }else {
            Swal.fire({
                title: this.props.i18n.t('notice'),
                text: this.props.i18n.t('send_question_toast'),
                icon: 'warning',
                confirmButtonText: this.props.i18n.t('ok_alert_msg'),
                cancelButtonText: this.props.i18n.t('reject_alert_msg'),
                showCancelButton: true,
            }).then((result) =>  {
                if(result.isConfirmed) {
                    this.send_request(answers)
                }
            })
        }
    }

    send_request(answers) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                question: answers
            })

        };
        fetch('https://uharmonymatch.com/api/v1/submit-answers/', requestOptions)

            .then((response) => {
                return response.json()
            })
            .then((response) => {
                if (response.status === 401) {
                    this.setState()
                } else {
                    if (response.status == 202) {
                        localStorage.removeItem('answers')
                        localStorage.removeItem('anc')
                        this.setState({submit_status: 202});
                    }
                }
            });
    }
}

function  mapState(state){
    return {
        user_profile: state.user_profile
    }
}
function withParams(Component) {
    return props => <Component {...props} params={useParams()} navigate={useNavigate()} />;
}

export default connect(mapState)(withParams(withTranslation()(SurveyPage)));