import React, {Component} from "react";
import {MDBRow} from "mdbreact";
import {Button} from "@mui/material";
import Footer from "./footer";
import {withTranslation} from "react-i18next";
import {LanguageContext} from "../LanguageContext";


class NotAllowedPage extends Component {
    static contextType = LanguageContext;

  render() {
      return (
          <body className="text-right bg-body" dir="rtl">
          <div className={'container-fluid'}>

              <MDBRow className="mt-5 pt-4"></MDBRow>
              <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center'}>
                  <div>
                      {/*<img width={200} className={'img-fluid'} src="images/success-reg.png" />*/}
                  </div>
              </MDBRow>
              <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center mt-3'}>
                  <div>
                      <p>
{this.props.i18n.t('you_dont_have_perm_to_see')}
                      </p>
                  </div>

              </MDBRow>
              <MDBRow className={'text-center align-items-center align-self-center justify-content-md-center'}>
                  <div>
                      <Button
                          className={'mt-3'}
                          onClick={(v) => this.backAfterSuccess(v)}
                          variant="contained"
                          sx={{
                              backgroundColor: "#C4C4C4",
                              fontSize: "15px",
                              color: "#fff",
                              ":hover": {
                                  bgcolor: "#355070",
                                  color: "#fff",
                              }
                          }}
                      >
                          {this.props.i18n.t('home_page_short')}
                      </Button>
                  </div>
              </MDBRow>
              <MDBRow className="mt-5 pt-4"></MDBRow>
              <Footer/>
          </div>
          </body>
      );
  }
    backAfterSuccess(v){
        window.location.href = '/'
    }
}

export default withTranslation()(NotAllowedPage);
