import {MDBCard, MDBRow} from "mdbreact";
import {MDBCheckbox, MDBRange} from "mdb-react-ui-kit";
import React from "react";
import {ToastContainer} from "react-toastify";
import {BrowserView, MobileView} from "react-device-detect";


class CheckBoxTypeQuestion extends React.Component{
    constructor(props) {
        super(props);

    }

    desktopView(){
        if(this.props.dir === 'fa') {
            return (
                <div className={'p-3'} style={{color: 'black', fontWeight: "bold"}}>
                    <MDBRow>
                        <div>
                            <label className={'m-4'}>
                                {this.props.question}
                            </label>
                        </div>
                    </MDBRow>
                    <MDBRow>
                        <div className={'p-2 justify-content-end align-items-end text-end'}
                             style={{marginRight: '30px'}}>
                            {this.props.answers.map((item, index) => (
                                this.getAnswerItem(item, index, false)
                            ))}
                        </div>
                    </MDBRow>
                </div>
            )
        }else {
            return (
                <div className={'p-3 ltr'} style={{color: 'black', fontWeight: "bold"}}>
                    <MDBRow>
                        <div  className={'p-2 justify-content-start align-items-start text-start'}>
                            <label className={'m-4 ltr text-start'} style={{fontWeight: "bold"}}>
                                {this.props.question}
                            </label>
                        </div>
                    </MDBRow>
                    <MDBRow>
                        <div className={'p-2 justify-content-start align-items-start text-start'}
                             style={{marginLeft: '30px'}}>
                            {this.props.answers.map((item, index) => (
                                this.getAnswerItem(item, index, false)
                            ))}
                        </div>
                    </MDBRow>
                </div>
            )
        }
    }

    mobileView(){
        if(this.props.dir === 'fa') {

            return (
                <div style={{color: 'black', fontWeight: "bold"}}>
                    <MDBRow>
                        <div>
                            <label>
                                {this.props.question}
                            </label>
                        </div>
                    </MDBRow>
                    <MDBRow>
                        <div className={'p-2 justify-content-end align-items-end text-end'}>
                            {this.props.answers.map((item, index) => (
                                this.getAnswerItem(item, index, true)
                            ))}
                        </div>
                    </MDBRow>
                </div>
            )
        }else {
            return (
                <div style={{color: 'black', fontWeight: "bold"}}>
                    <MDBRow>
                        <div>
                            <label className={'ltr text-start'}>
                                {this.props.question}
                            </label>
                        </div>
                    </MDBRow>
                    <MDBRow>
                        <div className={'p-2 justify-content-start align-items-start text-start'}>
                            {this.props.answers.map((item, index) => (
                                this.getAnswerItem(item, index, true)
                            ))}
                        </div>
                    </MDBRow>
                </div>
            )
        }
    }

    render() {

        return (
            <div>
                <ToastContainer/>
                <BrowserView>
                    {this.desktopView()}
                </BrowserView>
                <MobileView>
                    {this.mobileView()}
                </MobileView>
            </div>
        );


    }

    getAnswerItem(item, index, isMobile){
        if(isMobile){
            return(
                <MDBCheckbox
                    name={item.id}
                    value=''
                    id={item.id} label={item.title}
                    onChange={(e) => this.onSelectChk(e)}
                />

            );
        }
        return(

            <MDBCheckbox
                inline
                name={item.id}
                value=''
                id={item.id} label={item.title}
                onChange={(e) => this.onSelectChk(e)}
            />

        );
    }

    onSelectChk(e){
        this.props.listener(e, this.props.question_id, e.target.id);
    }
}

export default CheckBoxTypeQuestion;