import React, { useState, useEffect } from 'react';
import './chat.css';  // Import your custom styles
import { Link } from 'react-router-dom';

const ChatSupportIcon = () => {
    const [link, setLink] = useState('/contact-us/');  // Default link if no token

    useEffect(() => {
        // Check if the token exists in localStorage
        const token = localStorage.getItem('token');
        if (token) {
            // Token exists, set the URL to '/firstpage' or whatever URL you want
            setLink('/chatroom?isDirect=true&candidateAvatar=https://uharmonymatch.com/media/images/users/images/view-3d-woman-wearing-hijab.jpg&candidateID=support&candidateName=support'  );
        } else {
            // Token does not exist, set the URL to '/anotherpage' or any other fallback page
            setLink('/contact-us/');
        }
    }, []);  // Empty dependency array to check token only once when the component mounts

    return (
        <Link to={link}>
            <div className="chat-support-icon">
                <i className="fas fa-headset"></i> {/* FontAwesome Headset Icon */}
            </div>
        </Link>
    );
    // chatBTN(st) {
    //     // window.location.href = '/chatroom'
    //     window.location.href = '/chatroom?isDirect=true&candidateAvatar=' + this.state.data.image + '&candidateID=' + this.state.data.id + '&candidateName=' + this.state.data.name
    // }
};

export default ChatSupportIcon;
