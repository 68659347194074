const initiateState = {
    user_profile: localStorage.getItem('profile' || "{}")
}

const rootReducer = (state = initiateState, action) => {

    switch (action.type){
        case 'UPDATE_PROFILE':{
            let profile = action.item;
            return {
                ...state,
                user_profile: profile
            }
        }

        case 'DELETE_PROFILE':{
            return {
                ...state,
                user_profile: {}
            }
        }

        default: {
            return {user_profile: JSON.parse(initiateState.user_profile)}
        }
    }
}


export default rootReducer
