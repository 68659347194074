import React, { Component } from "react";
import './chat.css'
import {
    MDBCollapse,
    MDBIcon,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbarNav,
    MDBNavbarToggler
} from "mdb-react-ui-kit";
import IntroPage from "./IntroPage";
import { MDBCard, MDBCol, MDBInput, MDBRow } from "mdbreact";
import { FaArrowLeft, FaPaperPlane, FaWindowClose } from "react-icons/fa";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { BrowserView, MobileView } from "react-device-detect";
import {
    Avatar,
    ChatContainer,
    Conversation,
    ConversationHeader,
    ConversationList,
    MainContainer,
    Message,
    MessageInput,
    MessageList
} from "@chatscope/chat-ui-kit-react";
import NotAllowedPage from "./NotAllowedPage";
import RightSideNavbar from "./rightSideNavbar";
import { withTranslation } from "react-i18next";
import { LanguageContext } from "../LanguageContext";
import { useParams } from "react-router";
import { connect } from "react-redux";
import MobileChatRoom from "./MobileChatRoom";
import { FaMessage } from "react-icons/fa6";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import NoUsersFound from "./NoUsersFound";
import { Button, styled } from "@mui/material";
import Swal from "sweetalert2";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCaretSquareDown } from "@fortawesome/free-solid-svg-icons";


class SupportChatRoom extends Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        const urlParams = new URLSearchParams(window.location.search);

        const messagesEndRef = React.createRef()

        this.state = {
            open: false,
            profile: null,
            isIntro: localStorage.getItem('intro'),
            chatroom_list: [],
            chatroom_data: null,
            receiver_id: null,
            receiver: null,
            receiver_avatar: null,
            chat_room_id: null,
            isDirect: urlParams.get('isDirect'),
            candidateAvatar: urlParams.get('candidateAvatar'),
            candidateID: urlParams.get('candidateID'),
            candidateName: urlParams.get('candidateName'),
            messagesEndRef: messagesEndRef,
            lang: this.props.params.lang,
            is_report: false,
            report_text: null
        }


    }

    chooseLang() {
        if (this.state.lang === 'fa') {
            localStorage.setItem("lang", this.state.lang);
        } else {
            if (this.state.lang === 'en') {
                localStorage.setItem("lang", this.state.lang);
            } else {
                if (localStorage.getItem("lang") === null) {
                    localStorage.setItem("lang", 'fa');
                }
            }
        }

        var l = localStorage.getItem("lang")
        this.languageChangeListener = () => {
            var lang = localStorage.getItem("lang")

        }

        this.props.i18n.on('languageChanged', this.languageChangeListener);

        if (l !== null) {
            const { setLanguage } = this.context;
            setLanguage(l)

            this.props.i18n.changeLanguage(l);
        }
    }

    scrollToBottom() {
        if (this.state.chatroom_data !== null && this.state.chatroom_data.length !== 0) {
            this.state.messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
            window.scrollTo(0, document.body.scrollHeight);
        }
    }

    componentDidUpdate() {
        this.scrollToBottom()

    }

    componentDidMount() {
        this.chooseLang()
        this.getUserProfile()
        this.scrollToBottom()

    }

    getUserProfile() {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        };
        fetch('https://uharmonymatch.com/api/v1/profile/', requestOptions)

            .then((response) => {
                if (!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem('profile', JSON.stringify({
                        is_registration_complete: response.data.is_registration_complete,
                        is_seen_videos: response.data.is_seen_videos,
                        is_verified: response.data.is_verified
                    }));
                    if (this.state.isDirect === 'true') {
                        this.openChatRoom(
                            null,
                            this.state.candidateID,
                            this.state.candidateName,
                            this.state.candidateAvatar,
                            response.data
                        )
                    } else {
                        this.getChatrooms(response.data)
                    }
                }

            })
            .catch((error) => {


            });
    }

    getChatrooms(profile) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        };
        fetch('https://uharmonymatch.com/api/v1/chatrooms/', requestOptions)

            .then((response) => {
                if (!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        chatroom_list: response.data,
                        profile: profile
                    });
                } else {
                    this.setState({
                        profile: profile,
                    });
                }

            })
            .catch((error) => {
                this.setState({
                    profile: profile,
                });

            });
    }

    getMSGOnChange(value) {
        this.state.report_text = value.target.value
    }

    submit_report(e) {
        if (this.state.report_text != null) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify({
                    text: this.state.report_text,
                    uid: this.state.receiver_id,
                })
            };
            fetch('https://uharmonymatch.com/api/v1/report/', requestOptions)

                .then((response) => {
                    if (!response.ok) throw response.status;
                    else return response.json();
                })
                .then((response) => {
                    if (response.status === 200) {
                        toast.info(this.props.i18n.t('report_user_success_toast'), {
                            position: toast.POSITION.TOP_LEFT
                        });
                        this.setState(
                            {
                                is_report: false
                            }
                        )
                    }
                })
                .catch((error) => {
                    toast.error(this.props.i18n.t('report_user_error_toast'), {
                        position: toast.POSITION.TOP_LEFT
                    });

                });
        } else {
            toast.error(this.props.i18n.t('report_user_empty_toast'), {
                position: toast.POSITION.TOP_LEFT
            });

        }
    }
    cancel_report(e) {
        this.setState(
            { is_report: false }
        )
    }

    mobileView() {
        if (this.state.is_report) {
            return (
                <body className="text-right bg-body" dir="rtl">
                    <div className={'container-fluid'}>

                        <MDBRow className="mt-5 pt-4"></MDBRow>

                        <MDBRow className={'mt-5'}>
                            <div className={'text-center'} style={{ fontWeight: 'bold', }}>{this.props.i18n.t('report_btn')}</div>
                        </MDBRow>
                        <MDBRow className={'mt-5'}>
                            <div className={'text-center'} style={{ fontSize: '13px' }}>
                                <label className={'text-right'}>{this.props.i18n.t('contact_us_form_subject')}<span
                                    style={{ color: 'red' }}>*</span></label>
                            </div>
                            <MDBInput
                                // value={formValue.fname}
                                name='fname'
                                style={{ width: '80%', margin: 'auto' }}
                                onChange={this.getMSGOnChange.bind(this)}
                                id='validationCustom01'
                                type={'textarea'}
                                required
                            />

                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <div className={'text-center mb-3'} >
                                    <button className={'btn btn-success'}
                                        onClick={(e) => this.submit_report(e)}>{this.props.i18n.t('contact_us_form_send_btn')}</button>
                                </div>
                            </MDBCol>
                            <MDBCol>
                                <div className={'text-center mb-3'} >
                                    <button className={'btn btn-danger'}
                                        onClick={(e) => this.cancel_report(e)}>{this.props.i18n.t('cancel_btn_report')}</button>
                                </div>
                            </MDBCol>
                        </MDBRow>

                    </div>
                </body>
            )
        }

        if (this.state.isIntro == null || this.state.isIntro == false) {
            return (
                <body className="text-right bg-body" dir="rtl" style={{ height: "100vh" }}>
                    <div className={'container-fluid'}>
                        <MDBNavbar expand='lg' light bgColor='light' className={'p-3'} style={{ direction: 'ltr' }}>

                            <MDBNavbarBrand href='/'>
                                <img
                                    src='/logo192.png'
                                    height='30'
                                    alt='کانون هدایت'
                                    loading='lazy'
                                />
                            </MDBNavbarBrand>

                            <MDBNavbarToggler
                                aria-controls='navbarSupportedContent'
                                aria-expanded='false'
                                aria-label='Toggle navigation'
                                onClick={() => this.setState({ open: !this.state.open })}
                            >
                                <MDBIcon icon='bars' fas />
                            </MDBNavbarToggler>

                            <MDBCollapse navbar show={this.state.open}>

                                <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-end'>
                                    <MDBNavbarItem>
                                        <MDBNavbarLink aria-current='page' href='/my-profile' style={{ fontWeight: 'bold' }}>
                                            {this.props.i18n.t('my_profile')}
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                    <MDBNavbarItem>
                                        <MDBNavbarLink href='/about-us' style={{ fontWeight: 'bold' }}>{this.props.i18n.t('learning')}</MDBNavbarLink>
                                    </MDBNavbarItem>

                                    <MDBNavbarItem>
                                        <MDBNavbarLink href='/users' style={{ fontWeight: 'bold' }}>{this.props.i18n.t('users_list')}</MDBNavbarLink>
                                    </MDBNavbarItem>

                                    <MDBNavbarItem>
                                        <MDBNavbarLink href='/' style={{ fontWeight: 'bold' }}>{this.props.i18n.t('home_page_short')}</MDBNavbarLink>
                                    </MDBNavbarItem>

                                    <MDBNavbarItem>
                                        <MDBNavbarLink href='/logout' style={{ fontWeight: 'bold' }}>{this.props.i18n.t('sign_out')}</MDBNavbarLink>
                                    </MDBNavbarItem>
                                </MDBNavbarNav>


                            </MDBCollapse>

                        </MDBNavbar>

                        <IntroPage />

                    </div>
                </body>
            )
        }

        if ((this.state.chatroom_list === null || this.state.chatroom_list.length === 0) && this.state.isDirect === null) {

            return (
                <body scroll="no" className="text-right bg-body" dir="rtl" style={{ height: '100%', overflow: 'hidden' }}>

                    <MDBNavbar expand='lg' light bgColor='light' className={'p-3 mb-3'} style={{ direction: 'ltr' }}>

                        <MDBNavbarBrand href='/'>
                            <img
                                src='/logo192.png'
                                height='30'
                                alt='کانون هدایت'
                                loading='lazy'
                            />
                        </MDBNavbarBrand>

                        <MDBNavbarToggler
                            aria-controls='navbarSupportedContent'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            onClick={() => this.setState({ open: !this.state.open })}
                        >
                            <MDBIcon icon='bars' fas />
                        </MDBNavbarToggler>

                        <MDBCollapse navbar show={this.state.open}>

                            <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-end'>
                                <MDBNavbarItem>
                                    <MDBNavbarLink aria-current='page' href='/my-profile' style={{ fontWeight: 'bold' }}>
                                        {this.props.i18n.t('my_profile')}
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBNavbarLink href='/learning' style={{ fontWeight: 'bold' }}>{this.props.i18n.t('learning')}</MDBNavbarLink>
                                </MDBNavbarItem>

                                <MDBNavbarItem>
                                    <MDBNavbarLink href='/users' style={{ fontWeight: 'bold' }}>{this.props.i18n.t('users_list')}</MDBNavbarLink>
                                </MDBNavbarItem>

                                <MDBNavbarItem>
                                    <MDBNavbarLink href='/' style={{ fontWeight: 'bold' }}>{this.props.i18n.t('home_page_short')}</MDBNavbarLink>
                                </MDBNavbarItem>

                                <MDBNavbarItem>
                                    <MDBNavbarLink href='/logout' style={{ fontWeight: 'bold' }}>{this.props.i18n.t('sign_out')}</MDBNavbarLink>
                                </MDBNavbarItem>
                            </MDBNavbarNav>


                        </MDBCollapse>

                    </MDBNavbar>

                    <div className={'container-fluid'}>
                        <div style={{ position: "relative", height: "100vh", width: '100%' }} className={'text-center'}>

                            <Sidebar scrollable={false} style={{ width: '100%' }}>
                                <ConversationHeader.Content onClick={() => this.closeChat()}>
                                    <div style={{ height: '100px', verticalAlign: 'middle' }} className={'d-flex flex-grow-1 justify-content-center align-items-center'}>
                                        <FaWindowClose style={{ color: 'red', marginLeft: '10px', fontSize: '25px' }} />
                                        <span className={'text-center'}
                                            style={{

                                                alignSelf: "center",
                                                font: "IRANSansX"

                                            }}>
                                            {this.props.i18n.t('close_conversion')}
                                        </span>
                                    </div>
                                </ConversationHeader.Content>
                                <ConversationList style={{ background: 'white' }}>


                                    <Conversation.Content>
                                        <label style={{ color: 'black', background: 'white' }} className={'text-center mt-4'}>
                                            {this.props.i18n.t('do_not_have_msg')}
                                        </label>
                                    </Conversation.Content>

                                </ConversationList>
                            </Sidebar>

                        </div>
                    </div>

                </body>
            )
        }

        return (
            <body className="text-right bg-body" dir="rtl">
                <div className={'container-fluid'}>
                    {this.getChatRoomMobile()}

                </div>
            </body>

        )
    }

    desktopView() {

        if (this.props.i18n.language === 'fa') {

            if (this.state.is_report) {
                return (
                    <body className="text-right bg-body" dir="rtl">
                        <div className={'container-fluid'}>

                            <MDBRow className="mt-5 pt-4"></MDBRow>

                            <MDBRow className={'mt-5'}>
                                <div className={'text-center'} style={{ fontWeight: 'bold', }}>{this.props.i18n.t('report_btn')}</div>
                            </MDBRow>
                            <MDBRow className={'mt-5'}>
                                <div className={'text-center'} style={{ fontSize: '13px' }}>
                                    <label className={'text-right'}>{this.props.i18n.t('contact_us_form_subject')}<span
                                        style={{ color: 'red' }}>*</span></label>
                                </div>
                                <MDBInput
                                    // value={formValue.fname}
                                    name='fname'
                                    style={{ width: '60%', margin: 'auto' }}
                                    onChange={this.getMSGOnChange.bind(this)}
                                    id='validationCustom01'
                                    type={'textarea'}
                                    required
                                />

                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <div className={'text-left mb-3'} style={{ paddingLeft: '330px' }}>
                                        <button className={'btn btn-success'}
                                            onClick={(e) => this.submit_report(e)}>{this.props.i18n.t('contact_us_form_send_btn')}</button>
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <div className={'text-left mb-3'} style={{ paddingLeft: '330px' }}>
                                        <button className={'btn btn-danger'}
                                            onClick={(e) => this.cancel_report(e)}>{this.props.i18n.t('cancel_btn_report')}</button>
                                    </div>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    </body>
                )
            }

            if (this.state.isIntro == null || this.state.isIntro == false) {
                return (
                    <body className="text-right bg-body" dir="rtl" style={{ height: "100vh" }}>


                        <div className={'container-fluid'}>
                            <RightSideNavbar
                                profile={this.state.profile}
                                page={'my-profile'}
                            />

                            <IntroPage />

                        </div>
                    </body>
                )
            }
            if ((this.state.chatroom_list === null || this.state.chatroom_list.length === 0) && this.state.isDirect === null) {
                return (
                    <body className="text-right bg-body" dir="rtl" style={{ height: "100vh" }}>

                        <MDBRow>
                            <MDBCol size={"auto"} className={'p-0'}>
                                <div style={{ display: 'flex', height: '100%', minHeight: '400px' }} className={'text-center'}>
                                    <MDBCard className={'shadow-6-strong'}>
                                        <Sidebar style={{ height: "100vh" }} backgroundColor="#FFFFFF">

                                            <Menu>
                                                <div style={{ marginBottom: '50px' }}>
                                                    <MDBRow>
                                                        <div className={"text-center"}>

                                                            <img height={'100'} style=
                                                                {{
                                                                    borderRadius: '70%',
                                                                    marginTop: '90px',
                                                                    marginBottom: '20px'
                                                                }}
                                                                src={this.state.profile.picture} />
                                                        </div>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <div className={"text-center"}>
                                                            {this.state.profile.first_name} {this.state.profile.last_name}
                                                        </div>
                                                    </MDBRow>
                                                </div>

                                                <MenuItem component={<Link to="/my-profile" />}> {this.props.i18n.t('my_profile')} </MenuItem>
                                                <MenuItem component={<Link to={"/learning"} />}> {this.props.i18n.t('learning')} </MenuItem>
                                                <MenuItem component={<Link to="/users" />}> {this.props.i18n.t('users_list')} </MenuItem>
                                                <div className={'mt-lg-5'}></div>

                                                <MenuItem component={<Link to="/" />}>{this.props.i18n.t('home_page')}</MenuItem>
                                                <MenuItem component={<Link to="/logout" />} >{this.props.i18n.t('sign_out')}</MenuItem>

                                            </Menu>

                                        </Sidebar >
                                    </MDBCard>
                                </div>
                            </MDBCol>
                            <MDBCol className={'p-0'} style={{ background: '#355070' }}>

                                <div style={{ position: "relative", height: "500px", background: '#355070' }}>
                                    <MainContainer style={{ height: '100vh', direction: 'ltr', background: '#355070' }} responsive className={'p-2'}>
                                        <Sidebar position="left" scrollable={true} >
                                            <ConversationHeader style={{ background: '#355070' }}>

                                                <ConversationHeader.Content style={{ cursor: 'pointer' }} onClick={() => this.closeChat()}>
                                                    <div style={{ height: '100px', verticalAlign: 'middle' }} className={'d-flex flex-grow-1 justify-content-center align-items-center'}>
                                                        <FaWindowClose style={{ color: 'red', marginRight: '10px', fontSize: '25px' }} />
                                                        <span className={'text-center'}
                                                            style={{
                                                                color: "#fff",
                                                                alignSelf: "center",
                                                                font: "IRANSansX"

                                                            }}>
                                                            {this.props.i18n.t('close_conversion')}
                                                        </span>
                                                    </div>
                                                </ConversationHeader.Content>
                                            </ConversationHeader>
                                            <ConversationList style={{ background: '#355070' }}>

                                            </ConversationList>
                                        </Sidebar>

                                        <ChatContainer>
                                            <MessageList>
                                                <MessageList.Content style={{
                                                    display: "flex",
                                                    "flexDirection": "column",
                                                    "justifyContent": "center",
                                                    height: "100%",
                                                    textAlign: "center",
                                                    fontSize: "1.2em"
                                                }}>
                                                    {this.props.i18n.t('do_not_have_msg')}
                                                </MessageList.Content>
                                            </MessageList>
                                        </ChatContainer>
                                    </MainContainer>
                                </div>


                            </MDBCol>
                        </MDBRow>

                    </body>
                )
            }
            return (
                <body className="text-right bg-body" dir="rtl" style={{ height: "100vh" }}>


                    <div style={{ position: "relative", height: "100vh", background: '#355070' }}>
                        <MainContainer style={{ height: '100vh', direction: 'ltr', background: '#355070' }} responsive className={'p-2'}>
                            <Sidebar position="left" scrollable={true} >
                                <ConversationHeader style={{ background: '#355070' }}>

                                    <ConversationHeader.Content onClick={() => this.closeChat()}>
                                        <div style={{ height: '100px', verticalAlign: 'middle' }} className={'d-flex flex-grow-1 justify-content-center align-items-center'}>
                                            <FaWindowClose style={{ color: 'red', marginRight: '10px', fontSize: '25px' }} />
                                            <span className={'text-center'}
                                                style={{
                                                    color: "#fff",
                                                    alignSelf: "center",
                                                    font: "IRANSansX"

                                                }}>
                                                {this.props.i18n.t('close_conversion')}
                                            </span>
                                        </div>
                                    </ConversationHeader.Content>
                                </ConversationHeader>
                                <ConversationList style={{ background: '#355070' }}>



                                    {this.state.chatroom_list.map((item, index) => {
                                        if (Object.getOwnPropertyNames(item).length > 0) {
                                            return this.getChatroomListDesktop(index, item);
                                        }
                                    })}

                                </ConversationList>
                            </Sidebar>
                            {this.getChatRoomDesktop()}
                        </MainContainer>
                    </div>


                </body>
            )
        }

        if (this.state.is_report) {
            return (
                <body className="text-right bg-body" dir="ltr">
                    <div className={'container-fluid'}>

                        <MDBRow className="mt-5 pt-4"></MDBRow>

                        <MDBRow className={'mt-5'}>
                            <div className={'text-center'} style={{ fontWeight: 'bold', }}>{this.props.i18n.t('report_btn')}</div>
                        </MDBRow>
                        <MDBRow className={'mt-5'}>
                            <div className={'text-center'} style={{ fontSize: '13px' }}>
                                <label className={'text-right'}>{this.props.i18n.t('contact_us_form_subject')}<span
                                    style={{ color: 'red' }}>*</span></label>
                            </div>
                            <MDBInput
                                // value={formValue.fname}
                                name='fname'
                                style={{ width: '60%', margin: 'auto', direction: 'ltr' }}
                                onChange={this.getMSGOnChange.bind(this)}
                                id='validationCustom01'
                                type={'textarea'}
                                required
                            />

                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <div className={'text-left mb-3'} style={{ paddingLeft: '330px' }}>
                                    <button className={'btn btn-success'}
                                        onClick={(e) => this.submit_report(e)}>{this.props.i18n.t('contact_us_form_send_btn')}</button>
                                </div>
                            </MDBCol>
                            <MDBCol>
                                <div className={'text-left mb-3'} style={{ paddingLeft: '330px' }}>
                                    <button className={'btn btn-danger'}
                                        onClick={(e) => this.cancel_report(e)}>{this.props.i18n.t('cancel_btn_report')}</button>
                                </div>
                            </MDBCol>
                        </MDBRow>

                    </div>
                </body>
            )
        }

        if (this.state.isIntro == null || this.state.isIntro == false) {
            return (
                <body className="text-left bg-body ltr" dir="rtl" style={{ height: "100vh", backgroundColor: '#355070' }}>
                    <div className={'container-fluid'}>

                        <RightSideNavbar
                            profile={this.state.profile}
                            page={'my-profile'}
                        />

                        <div style={{ marginLeft: '250px', padding: '0px 10px', backgroundColor: '#355070' }}>

                            <IntroPage />

                        </div>
                    </div>
                </body>
            )
        }
        if ((this.state.chatroom_list === null || this.state.chatroom_list.length === 0) && this.state.isDirect === null) {
            return (
                <body className="text-right bg-body" dir="rtl" style={{ height: "100vh" }}>

                    <MDBRow>
                        <MDBCol size={"auto"} className={'p-0'}>
                            <div style={{ display: 'flex', height: '100%', minHeight: '400px' }} className={'text-center'}>
                                <MDBCard className={'shadow-6-strong'}>
                                    <Sidebar style={{ height: "100vh" }} backgroundColor="#FFFFFF">

                                        <Menu>
                                            <div style={{ marginBottom: '50px' }}>
                                                <MDBRow>
                                                    <div className={"text-center"}>

                                                        <img height={'100'} style=
                                                            {{
                                                                borderRadius: '70%',
                                                                marginTop: '90px',
                                                                marginBottom: '20px'
                                                            }}
                                                            src={this.state.profile.picture} />
                                                    </div>
                                                </MDBRow>
                                                <MDBRow>
                                                    <div className={"text-center"}>
                                                        {this.state.profile.first_name} {this.state.profile.last_name}
                                                    </div>
                                                </MDBRow>
                                            </div>

                                            <MenuItem component={<Link to="/my-profile" />}> {this.props.i18n.t('my_profile')} </MenuItem>
                                            <MenuItem component={<Link to={"/learning"} />}> {this.props.i18n.t('learning')} </MenuItem>
                                            <MenuItem component={<Link to="/users" />}> {this.props.i18n.t('users_list')} </MenuItem>
                                            <div className={'mt-lg-5'}></div>

                                            <MenuItem component={<Link to="/" />}>{this.props.i18n.t('home_page')}</MenuItem>
                                            <MenuItem component={<Link to="/logout" />} >{this.props.i18n.t('sign_out')}</MenuItem>

                                        </Menu>

                                    </Sidebar >
                                </MDBCard>
                            </div>
                        </MDBCol>
                        <MDBCol className={'p-0'} style={{ background: '#355070' }}>

                            <div style={{ position: "relative", height: "500px", background: '#355070' }}>
                                <MainContainer style={{ height: '100vh', direction: 'ltr', background: '#355070' }} responsive className={'p-2'}>
                                    <Sidebar position="left" scrollable={true} >
                                        <ConversationHeader style={{ background: '#355070' }}>

                                            <ConversationHeader.Content style={{ cursor: 'pointer' }} onClick={() => this.closeChat()}>
                                                <div style={{ height: '100px', verticalAlign: 'middle' }} className={'d-flex flex-grow-1 justify-content-center align-items-center'}>
                                                    <FaWindowClose style={{ color: 'red', marginRight: '10px', fontSize: '25px' }} />
                                                    <span className={'text-center'}
                                                        style={{
                                                            color: "#fff",
                                                            alignSelf: "center",
                                                            font: "IRANSansX"

                                                        }}>
                                                        {this.props.i18n.t('close_conversion')}
                                                    </span>
                                                </div>
                                            </ConversationHeader.Content>
                                        </ConversationHeader>
                                        <ConversationList style={{ background: '#355070' }}>

                                        </ConversationList>
                                    </Sidebar>

                                    <ChatContainer>
                                        <MessageList>
                                            <MessageList.Content style={{
                                                display: "flex",
                                                "flexDirection": "column",
                                                "justifyContent": "center",
                                                height: "100%",
                                                textAlign: "center",
                                                fontSize: "1.2em"
                                            }}>
                                                {this.props.i18n.t('do_not_have_msg')}
                                            </MessageList.Content>
                                        </MessageList>
                                    </ChatContainer>
                                </MainContainer>
                            </div>


                        </MDBCol>
                    </MDBRow>

                </body>
            )
        }
        return (
            <body className="text-right bg-body" dir="rtl" style={{ height: "100vh" }}>


                <div style={{ position: "relative", height: "100vh", background: '#355070' }}>
                    <MainContainer style={{ height: '100vh', direction: 'ltr', background: '#355070' }} responsive className={'p-2'}>
                        <Sidebar position="left" scrollable={true} >
                            <ConversationHeader style={{ background: '#355070' }}>

                                <ConversationHeader.Content onClick={() => this.closeChat()}>
                                    <div style={{ height: '100px', verticalAlign: 'middle' }} className={'d-flex flex-grow-1 justify-content-center align-items-center'}>
                                        <FaWindowClose style={{ color: 'red', marginRight: '10px', fontSize: '25px' }} />
                                        <span className={'text-center'}
                                            style={{
                                                color: "#fff",
                                                alignSelf: "center",
                                                font: "IRANSansX"

                                            }}>
                                            {this.props.i18n.t('close_conversion')}
                                        </span>
                                    </div>
                                </ConversationHeader.Content>
                            </ConversationHeader>
                            <ConversationList style={{ background: '#355070' }}>



                                {this.state.chatroom_list.map((item, index) => {
                                    if (Object.getOwnPropertyNames(item).length > 0) {
                                        return this.getChatroomListDesktop(index, item);
                                    }
                                })}

                            </ConversationList>
                        </Sidebar>
                        {this.getChatRoomDesktop()}
                    </MainContainer>
                </div>
            </body>
        )

    }

    render() {
        if (localStorage.getItem("token") === null) {
            return (
                <div style={{ fontWeight: 'bold', fontSize: '25px', direction: 'ltr' }}>
                    Forbidden!
                </div>
            );
        }
        if (this.state.profile !== null) {
            if (this.state.profile.is_registration_complete !== false) {
                return (
                    <div ref={(el) => {
                        this.state.messagesEndRef = el;
                    }}>
                        <ToastContainer />
                        <BrowserView>
                            {this.desktopView()}
                        </BrowserView>
                        <MobileView>
                            {this.mobileView()}
                        </MobileView>
                    </div>
                );
            } else {
                return (
                    <NoUsersFound
                        profile={this.state.profile}
                        isEn={this.props.i18n.language === 'en'}
                    />
                );
            }
        } else {
            return (
                <div></div>
            )
        }

    }

    closeChat() {
        window.location.href = "/my-profile";
    }

    backToChatListMobile() {
        if (this.state.isDirect === 'true') {
            window.location.href = '/user/?id=' + this.state.candidateID
        } else {
            this.setState({
                chatroom_data: null,
                receiver_id: null,
                receiver: null,
                receiver_avatar: null,
                chat_room_id: null
            });
        }
    }

    openChatRoom(id, receiver_id, receiver, receiver_avatar, profile) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token')
            },
        };
        const url = 'https://uharmonymatch.com/api/v1/chatroom/?' + new URLSearchParams({
            chat_room_id: id,
            candidate: receiver_id
        })
        fetch(url, requestOptions)

            .then((response) => {
                if (!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {

                if (response.status === 200) {
                    if (this.state.chatroom_list.length === 0) {
                        this.state.chatroom_list.push(
                            {
                                chat_room_id: response.chat_room_id,
                                sender_id: receiver_id,
                                sender: receiver,
                                sender_avatar: receiver_avatar,
                                msg: null,
                            }
                        )
                    }
                    if (profile !== null) {
                        this.setState({
                            chatroom_data: response.data,
                            receiver_id: receiver_id,
                            receiver: receiver,
                            receiver_avatar: receiver_avatar,
                            chat_room_id: response.chat_room_id,
                            profile: profile
                        });
                    } else {
                        this.setState({
                            chatroom_data: response.data,
                            receiver_id: receiver_id,
                            receiver: receiver,
                            receiver_avatar: receiver_avatar,
                            chat_room_id: response.chat_room_id,
                        });
                    }
                }

            })
            .catch((error) => {
            });
    }

    chatRoomHeader(id) {
        window.location.href = '/user/?id=' + id
    }

    sendMSGMobile() {
        var msgtxt = document.getElementsByName('MSGTXT')[0].value;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                chat_room_id: this.state.chat_room_id,
                receiver: this.state.receiver_id,
                msg: msgtxt,
            })
        };
        fetch('https://uharmonymatch.com/api/v1/chatroom/', requestOptions)

            .then((response) => {
                if (!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {
                if (response.status === 202) {
                    document.getElementsByName('MSGTXT')[0].value = '';
                    this.setState(
                        {
                            chatroom_data: response.data,
                        }
                    )
                }

            })
            .catch((error) => {


            });
    }
    sendMSG(msgtxt) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                chat_room_id: this.state.chat_room_id,
                receiver: this.state.receiver_id,
                msg: msgtxt,
            })
        };
        fetch('https://uharmonymatch.com/api/v1/chatroom/', requestOptions)

            .then((response) => {
                if (!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {
                if (response.status === 202) {
                    this.setState(
                        {
                            chatroom_data: response.data,
                        }
                    )
                }

            })
            .catch((error) => {
                if (error == 403) {
                    toast.error(this.props.i18n.t('reported_user_msg'), {
                        position: toast.POSITION.TOP_LEFT
                    });
                }

            });
    }

    getChatroomListDesktop(index, item) {

        return (
            <div>
                <Conversation.Content style={{ background: '#355070', color: '#fff' }}
                    onClick={() => this.openChatRoom(
                        item.chat_room_id,
                        item.sender_id,
                        item.sender,
                        item.sender_avatar,
                        null)} >
                    <MDBRow>
                        <MDBCol className={'text-left m-1'} size={'2'}>
                            <img className={'p-1 chat_avatar'} src={item.sender_avatar} />
                        </MDBCol>
                        <MDBCol className={'text-left m-1'} style={{ paddingLeft: '20px' }}>

                            <MDBRow>
                                <span
                                    style={{
                                        color: "#fff",
                                        alignSelf: "center",
                                        font: "IRANSansX",
                                        fontWeight: 'bold',
                                        fontSize: '15px'

                                    }}>
                                    {item.sender}
                                </span>
                            </MDBRow>
                            <MDBRow>
                                <span
                                    style={{
                                        color: "#fff",
                                        textAlign: 'right',
                                        font: "IRANSansX",
                                        fontSize: '10px'
                                    }}>
                                    {item.msg}
                                </span>
                            </MDBRow>

                        </MDBCol>

                    </MDBRow>
                </Conversation.Content>
                <div style={{ width: '100%', height: '1px', background: '#919191' }}></div>
            </div>
        )
    }

    reportBTN(e) {
        // Swal.fire({
        //     title: this.props.i18n.t('notice'),
        //     text: this.props.t('report_user_msg'),
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonText: this.props.t('ok_btn'),
        //     cancelButtonText: this.props.t('cancel_btn')
        // }).then(function (result) {
        //     if(result.isConfirmed){
        this.setState(
            { is_report: true }, () => { console.log(this.state.is_report, 'dealersOverallTotal1') }
        )
        // }

        // })
    }

    getChatRoomDesktop() {

        if (this.state.chatroom_data !== null && this.state.chatroom_data.length !== 0) {
            return (
                <ChatContainer>
                    <ConversationHeader >
                        <Avatar src={this.state.receiver_avatar} name={this.state.receiver} onClick={() => this.chatRoomHeader(this.state.receiver_id)} />
                        <ConversationHeader.Content userName={this.state.receiver} onClick={() => this.chatRoomHeader(this.state.receiver_id)} />
                        <ConversationHeader.Actions>
                            <button className={'btn-danger btn'} style={{ fontSize: '10px', fontFamily: 'IRANSansX' }}
                                onClick={(v) => this.reportBTN(v)}>{this.props.i18n.t('report_btn')}</button>
                        </ConversationHeader.Actions>
                    </ConversationHeader>
                    <MessageList style={{ backgroundImage: `url(/images/chatbg.png)`, }}>
                        {this.state.chatroom_data.map((item, index) => (
                            this.getChatRoomDesktopMsgList(index, item)
                        ))}
                    </MessageList>
                    <MessageInput attachButton={false} placeholder={this.props.i18n.t('msg_title')} onSend={(m) => this.sendMSG(m)} />
                </ChatContainer>
            );
        } else {
            if (this.state.isDirect === 'true') {
                return (
                    <ChatContainer>
                        <ConversationHeader>
                            <Avatar src={this.state.receiver_avatar} name={this.state.receiver} onClick={() => this.chatRoomHeader(this.state.receiver_id)} />
                            <ConversationHeader.Content userName={this.state.receiver} onClick={() => this.chatRoomHeader(this.state.receiver_id)} />
                            <ConversationHeader.Actions>
                                <button className={'btn-danger btn'} style={{ fontSize: '10px', fontFamily: 'IRANSansX' }} onClick={(v) => this.reportBTN(v)}>{this.props.i18n.t('report_btn')}</button>
                            </ConversationHeader.Actions>
                        </ConversationHeader>
                        <MessageList style={{ backgroundImage: `url(/images/chatbg.png)`, }}>

                        </MessageList>
                        <MessageInput attachButton={false} placeholder={this.props.i18n.t('msg_title')} onSend={(m) => this.sendMSG(m)} />
                    </ChatContainer>
                );
            }
        }
    }

    getChatRoomDesktopMsgList(index, item) {
        if (item.is_receiver) {
            return (
                <div>
                    <Message model={{
                        message: item.msg,
                        sentTime: "15 mins ago",
                        sender: item.receiver,
                        direction: "incoming",
                        position: "single"
                    }}>
                        <Avatar src={item.sender_avatar} name={item.receiver} />
                    </Message>
                </div>
            );
        } else {
            return (
                <div>

                    <Message model={{
                        message: item.msg,
                        sentTime: "15 mins ago",
                        sender: item.sender,
                        direction: "outgoing",
                        position: "single"
                    }} >
                        <Avatar src={item.sender_avatar} name={item.sender} />
                    </Message>
                </div>
            )
        }
    }

    getChatroomListMobile(index, item) {
        return (
            <div>
                <Conversation onClick={() => this.openChatRoom(
                    item.chat_room_id,
                    item.sender_id,
                    item.sender,
                    item.sender_avatar,
                    null)}
                    name={item.sender} info={item.msg}>

                    <Avatar src={item.sender_avatar} name={item.sender} />
                </Conversation>
            </div>
        )
    }

    getChatRoomMobile() {

        if (this.state.chatroom_data !== null && this.state.chatroom_data.length !== 0) {

            return (
                <div>

                    <div style={{
                        position: 'fixed',
                        top: 0,
                        zIndex: 1000,
                        width: '100%',
                        textAlign: 'center',
                        padding: '0px',
                        marginBottom: '30px'
                    }}>
                        <MDBRow>
                            <MDBCard className={'mb-3'}>
                                <MDBRow style={{ height: '70px' }}
                                    className={'text-center align-content-center align-items-center'}>
                                    <MDBCol className={'text-right'} size={2}>

                                        <Dropdown noCaret>
                                            <Dropdown.Toggle className="no-caret" variant="white" id="dropdown-basic">
                                                <FontAwesomeIcon icon={faBars} />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>

                                                <Dropdown.Item onClick={this.reportBTN.bind(this)}>{this.props.i18n.t('report_btn')}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </MDBCol>
                                    <MDBCol className={'text-right'} size={4}>
                                        <div className={'m-2'}>
                                            <img src={this.state.receiver_avatar}
                                                className={" img-fluid rounded-circle rounded-circle-custom chat_avatar"}
                                                // style={{ width: '50px', height: '50px' }} 
                                                />
                                        </div>
                                    </MDBCol>
                                    <MDBCol className={'text-center align-content-center align-items-center'} size={3}>
                                        <center>{this.state.receiver}</center>
                                    </MDBCol>

                                    <MDBCol className={'text-center align-content-center align-items-center'} size={3}>
                                        <center><FaArrowLeft onClick={() => this.backToChatListMobile()} /></center>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCard>
                        </MDBRow>
                    </div>

                    <MDBRow style={{ marginTop: '50px', height: '90vh', marginBottom: '100px' }}>
                        <div style={{ backgroundImage: `url(/images/chatbg.png)`, }}>
                            {this.state.chatroom_data.map((item, index) => {
                                return (
                                    this.getChatRoomMobileMsgList(index, item, this.state.chatroom_data.length)
                                )
                            })}

                        </div>
                    </MDBRow>

                    <div style={{
                        position: 'fixed',
                        bottom: 0,
                        zIndex: 100,
                        width: '100%',
                        textAlign: 'center',
                        marginTop: '400px',
                        paddingLeft: '0px'
                    }}>
                        <MDBRow>
                            <MDBCard className={'mt-3 rounded-0'} style={{ borderColor: '#8ED1DA' }}>
                                <MDBRow className={'text-center align-content-center align-items-center'}>
                                    <MDBCol className={'text-center align-content-center align-items-center'} size={9}>
                                        <textarea name="MSGTXT" cols="40" rows="2"
                                            placeholder={this.props.i18n.t('msg_title')}
                                            className={'w-100 p-2 m-2'} />
                                    </MDBCol>
                                    <MDBCol className={'text-center align-content-center align-items-center'} size={3}>

                                        <center><FaPaperPlane style={{ color: '#8ED1DA', fontSize: '23px' }}
                                            onClick={(e) => this.sendMSGMobile()} /></center>

                                    </MDBCol>
                                </MDBRow>
                            </MDBCard>
                        </MDBRow>
                    </div>

                </div>
            );
        } else {
            if (this.state.isDirect === 'true') {
                return (
                    <div>
                        <div style={{ position: 'fixed', top: 0, zIndex: 1000, width: '100%', textAlign: 'center', padding: '10px', marginBottom: '30px' }}>
                            <MDBRow>
                                <MDBCard className={'mb-3 mt-3'}>
                                    <MDBRow style={{ height: '70px' }} className={'text-center align-content-center align-items-center'}>

                                        <MDBCol className={'text-right'} size={2}>

                                            <Dropdown noCaret>
                                                <Dropdown.Toggle className="no-caret" variant="white" id="dropdown-basic">
                                                    <FontAwesomeIcon icon={faBars} />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>

                                                    <Dropdown.Item onClick={this.reportBTN.bind(this)}>{this.props.i18n.t('report_btn')}</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                        </MDBCol>

                                        <MDBCol className={'text-right'} size={4}>
                                            <div className={'m-2'}>
                                                <img src={this.state.receiver_avatar} className={" img-fluid rounded-circle rounded-circle-custom chat_avatar"}
                                                    // style={{ width: '50px', height: '50px' }} 
                                                    />
                                            </div>
                                        </MDBCol>
                                        <MDBCol className={'text-center align-content-center align-items-center'} size={6}>
                                            <center>{this.state.receiver}</center>
                                        </MDBCol>
                                        <MDBCol className={'text-center align-content-center align-items-center'} size={3}>
                                            <center><FaArrowLeft onClick={() => this.backToChatListMobile()} /></center>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCard>
                            </MDBRow>
                        </div>

                        <MDBRow style={{ marginTop: '100px', height: '90vh', marginBottom: '200px' }}>
                            <div style={{ backgroundImage: `url(/images/chatbg.png)`, }}  >


                            </div>
                        </MDBRow>

                        <div style={{ position: 'fixed', bottom: 0, zIndex: 1001, width: '90%', textAlign: 'center', marginBottom: '20px', marginTop: '400px', }}>
                            <MDBCard className={'mb-1 mt-3 text-center rounded-5'} style={{ borderColor: '#8ED1DA' }}>
                                <MDBRow className={'text-center align-content-center align-items-center'}>
                                    <MDBCol className={'text-center align-content-center align-items-center'} size={9}>
                                        <textarea name="MSGTXT" cols="40" rows="2" placeholder={this.props.i18n.t('msg_title')} className={'w-100 p-2 m-2'} />
                                    </MDBCol>
                                    <MDBCol className={'text-center align-content-center align-items-center'} size={3}>

                                        <center><FaPaperPlane style={{ color: '#8ED1DA', fontSize: '23px' }}
                                            onClick={(e) => this.sendMSGMobile()} /></center>

                                    </MDBCol>
                                </MDBRow>
                            </MDBCard>
                        </div>

                    </div>
                );
            }
        }
        return (
            <div>
                <Sidebar scrollable={true} style={{ width: '100%' }}>
                    <div className={'text-center'} style={{ font: 'IRANSansX' }}>
                        <ConversationHeader.Content onClick={() => this.closeChat()}>
                            <div style={{ height: '100px', verticalAlign: 'middle' }} className={'d-flex flex-grow-1 justify-content-center align-items-center'}>
                                <FaWindowClose style={{ color: 'red', marginLeft: '10px', fontSize: '25px' }} />
                                <span className={'text-center'}
                                    style={{

                                        alignSelf: "center",
                                        font: "IRANSansX"

                                    }}>
                                    {this.props.i18n.t('close_conversion')}
                                </span>
                            </div>
                        </ConversationHeader.Content>
                    </div>
                    <ConversationList >

                        {this.state.chatroom_list.map((item, index) => {
                            if (Object.getOwnPropertyNames(item).length > 0) {
                                return this.getChatroomListMobile(index, item)
                            }
                        })}
                    </ConversationList>
                </Sidebar>
            </div>

        );
    }

    getChatRoomMobileMsgList(index, item, length) {

        if (item.is_receiver) {
            if (length === index + 1) {
                return (

                    <MobileChatRoom
                        image={item.receiver_avatar}
                        msg={item.msg}
                        is_receiver={true}
                        is_last={true}
                    />


                );
            }
            return (

                <MobileChatRoom
                    image={item.receiver_avatar}
                    msg={item.msg}
                    is_receiver={true}
                    is_last={false}
                />


            );
        } else {

            if (length === index + 1) {

                return (
                    <MobileChatRoom
                        image={item.sender_avatar}
                        msg={item.msg}
                        is_receiver={false}
                        is_last={true}
                    />
                )
            }

            return (
                <MobileChatRoom
                    image={item.sender_avatar}
                    msg={item.msg}
                    is_receiver={false}
                    is_last={false}
                />

            )
        }
    }

}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

export default connect()(withParams(withTranslation()(SupportChatRoom)));