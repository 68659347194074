import React, {Component} from "react";
import IntroPage from "./IntroPage";
import {MDBCard, MDBCol, MDBRow, MDBTable, MDBTableBody} from "mdbreact";
import {FaMessage} from "react-icons/fa6";
import {FaAngleLeft, FaAngleRight, FaCheckCircle, FaSignOutAlt} from "react-icons/fa";
import {Menu, menuClasses, MenuItem, Sidebar} from "react-pro-sidebar";
import {Link} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {BrowserView, MobileView} from "react-device-detect";
import {
    MDBCollapse,
    MDBIcon,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbarNav,
    MDBNavbarToggler
} from "mdb-react-ui-kit";
import NotAllowedPage from "./NotAllowedPage";
import RightSideNavbar from "./rightSideNavbar";
import {withTranslation} from "react-i18next";
import {LanguageContext} from "../LanguageContext";
import {useParams} from "react-router";
import {connect} from "react-redux";


class LearningPage extends Component{

    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            profile: null,
            isIntro: localStorage.getItem('intro'),
            lang: this.props.params.lang,

        }

    }

    chooseLang() {

        if(this.state.lang === 'fa'){
            localStorage.setItem("lang", this.state.lang);
        }else {
            if(this.state.lang === 'en'){
                localStorage.setItem("lang", this.state.lang);
            }else {
                if(localStorage.getItem("lang") === null) {
                    localStorage.setItem("lang", 'en');
                }
            }
        }

        var l = localStorage.getItem("lang")
        this.languageChangeListener = () =>{
            var lang = localStorage.getItem("lang")

        }

        this.props.i18n.on('languageChanged', this.languageChangeListener);

        if (l !== null) {
            const {setLanguage} = this.context;
            setLanguage(l)

            this.props.i18n.changeLanguage(l);
        }
    }

    componentDidMount() {
        this.chooseLang()
        this.getUserProfile()
    }

    getUserProfile(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('https://uharmonymatch.com/api/v1/profile/', requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {
                if(response.status === 200) {
                    localStorage.setItem('profile', JSON.stringify({
                        is_registration_complete: response.data.is_registration_complete,
                        is_seen_videos: response.data.is_seen_videos,
                        is_verified: response.data.is_verified
                    }));
                    this.setState({
                        profile: response.data
                    });
                }

            })
            .catch((error) => {
                if(error == 401){
                    localStorage.removeItem('token')
                    localStorage.removeItem('profile')
                    window.location.href = "/login";
                }

            });
    }

    mobileView(){

        const { setLanguage } = this.context;

        const changeLangBTN = (newLanguage) => {
            localStorage.setItem("lang", newLanguage);
            setLanguage(newLanguage);
            window.location.reload()
        };

        if(this.props.i18n.language=== 'en') {
            if(this.state.isIntro == null || this.state.isIntro == false){
                return (
                    <body className="text-right bg-body" dir="rtl" style = {{height:"100vh"}}>
                    <div className={'container-fluid'}>
                        <MDBNavbar expand='lg' light bgColor='light' className={'p-3'} style={{direction: 'ltr'}}>

                            <MDBNavbarBrand href='/'>
                                <img
                                    src='/logo192.png'
                                    height='30'
                                    alt='کانون هدایت'
                                    loading='lazy'
                                />
                            </MDBNavbarBrand>

                            <MDBNavbarToggler
                                aria-controls='navbarSupportedContent'
                                aria-expanded='false'
                                aria-label='Toggle navigation'
                                onClick={() => this.setState({open: !this.state.open})}
                            >
                                <MDBIcon icon='bars' fas />
                            </MDBNavbarToggler>

                            <MDBCollapse navbar show={this.state.open}>

                                <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-end'>
                                    <MDBNavbarItem>
                                        <MDBNavbarLink active aria-current='page' href='/my-profile' style={{fontWeight: 'bold'}}>
                                            {this.props.i18n.t('my_profile')}
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                    <MDBNavbarItem>
                                        <MDBNavbarLink href='/about-us' style={{fontWeight: 'bold'}}>{this.props.i18n.t('learning')}</MDBNavbarLink>
                                    </MDBNavbarItem>

                                    <MDBNavbarItem>
                                        <MDBNavbarLink href='/users' style={{fontWeight: 'bold'}}>{this.props.i18n.t('users_list')}</MDBNavbarLink>
                                    </MDBNavbarItem>

                                    <MDBNavbarItem>
                                        <MDBNavbarLink href='/' style={{fontWeight: 'bold'}}>{this.props.i18n.t('home_page_short')}</MDBNavbarLink>
                                    </MDBNavbarItem>

                                    <MDBNavbarItem>
                                        <MDBNavbarLink href='/logout' style={{fontWeight: 'bold'}}>{this.props.i18n.t('sign_out')}</MDBNavbarLink>
                                    </MDBNavbarItem>

                                    <div className={'mt-3'}>
                                        <MDBIcon flag='uk' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('en')}/>
                                        <MDBIcon flag='ir' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('fa')}/>
                                    </div>

                                </MDBNavbarNav>


                            </MDBCollapse>

                        </MDBNavbar>

                        <IntroPage/>

                    </div>
                    </body>
                )
            }
            return(
                <body className="text-right bg-body" dir="rtl">

                <MDBNavbar expand='lg' light bgColor='light' className={'p-3 mb-3 text-start'} style={{direction: 'ltr'}}>

                    <MDBNavbarBrand href='/'>
                        <img
                            src='/logo192.png'
                            height='30'
                            alt='کانون هدایت'
                            loading='lazy'
                        />
                    </MDBNavbarBrand>

                    <MDBNavbarToggler
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => this.setState({open: !this.state.open})}
                    >
                        <MDBIcon icon='bars' fas />
                    </MDBNavbarToggler>

                    <MDBCollapse navbar show={this.state.open}>

                        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-end'>
                            <MDBNavbarItem>
                                <MDBNavbarLink aria-current='page' href='/my-profile' style={{fontWeight: 'bold'}}>
                                    {this.props.i18n.t('my_profile')}
                                </MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink  active href='/learning' style={{fontWeight: 'bold'}}>{this.props.i18n.t('learning')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/users' style={{fontWeight: 'bold'}}>{this.props.i18n.t('users_list')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/' style={{fontWeight: 'bold'}}>{this.props.i18n.t('home_page_short')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/logout' style={{fontWeight: 'bold'}}>{this.props.i18n.t('sign_out')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <div className={'mt-3'}>
                                <MDBIcon flag='uk' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('en')}/>
                                <MDBIcon flag='ir' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('fa')}/>
                            </div>

                        </MDBNavbarNav>


                    </MDBCollapse>

                </MDBNavbar>
                <div className={'container-fluid'}>
                    <MDBRow className={'m-0'}
                            style={{
                                minHeight: '110px',
                            }}>
                        <div className={'mt-5 text-left'}>
                            <button className="btn" style={{background: '#FF686B', color: '#fff'}} onClick={(v) => this.chatBTN(v)}>
                                <label><FaMessage/> {this.props.i18n.t('conversion_btn_title')}</label>
                            </button>
                        </div>
                    </MDBRow>

                    {this.getVideoRowMobile(this.state.profile.is_seen_videos, this.props.i18n.language=== 'en')}
                    {this.getQuestionRowMobile(this.state.profile.is_seen_videos, this.state.profile.is_registration_complete, this.props.i18n.language=== 'en')}
                </div>
                </body>
            )
        }else {
            if(this.state.isIntro == null || this.state.isIntro == false){
                return (
                    <body className="text-right bg-body" dir="rtl" style = {{height:"100vh"}}>
                    <div className={'container-fluid'}>
                        <MDBNavbar expand='lg' light bgColor='light' className={'p-3'} style={{direction: 'ltr'}}>

                            <MDBNavbarBrand href='/'>
                                <img
                                    src='/logo192.png'
                                    height='30'
                                    alt='کانون هدایت'
                                    loading='lazy'
                                />
                            </MDBNavbarBrand>

                            <MDBNavbarToggler
                                aria-controls='navbarSupportedContent'
                                aria-expanded='false'
                                aria-label='Toggle navigation'
                                onClick={() => this.setState({open: !this.state.open})}
                            >
                                <MDBIcon icon='bars' fas />
                            </MDBNavbarToggler>

                            <MDBCollapse navbar show={this.state.open}>

                                <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-end'>
                                    <MDBNavbarItem>
                                        <MDBNavbarLink active aria-current='page' href='/my-profile' style={{fontWeight: 'bold'}}>
                                            {this.props.i18n.t('my_profile')}
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                    <MDBNavbarItem>
                                        <MDBNavbarLink href='/about-us' style={{fontWeight: 'bold'}}>{this.props.i18n.t('learning')}</MDBNavbarLink>
                                    </MDBNavbarItem>

                                    <MDBNavbarItem>
                                        <MDBNavbarLink href='/users' style={{fontWeight: 'bold'}}>{this.props.i18n.t('users_list')}</MDBNavbarLink>
                                    </MDBNavbarItem>

                                    <MDBNavbarItem>
                                        <MDBNavbarLink href='/' style={{fontWeight: 'bold'}}>{this.props.i18n.t('home_page_short')}</MDBNavbarLink>
                                    </MDBNavbarItem>

                                    <MDBNavbarItem>
                                        <MDBNavbarLink href='/logout' style={{fontWeight: 'bold'}}>{this.props.i18n.t('sign_out')}</MDBNavbarLink>
                                    </MDBNavbarItem>

                                    <div className={'mt-3'}>
                                        <MDBIcon flag='uk' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('en')}/>
                                        <MDBIcon flag='ir' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('fa')}/>
                                    </div>

                                </MDBNavbarNav>


                            </MDBCollapse>

                        </MDBNavbar>

                        <IntroPage/>

                    </div>
                    </body>
                )
            }
            return(
                <body className="text-right bg-body" dir="rtl">

                <MDBNavbar expand='lg' light bgColor='light' className={'p-3 mb-3'} style={{direction: 'ltr'}}>

                    <MDBNavbarBrand href='/'>
                        <img
                            src='/logo192.png'
                            height='30'
                            alt='کانون هدایت'
                            loading='lazy'
                        />
                    </MDBNavbarBrand>

                    <MDBNavbarToggler
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => this.setState({open: !this.state.open})}
                    >
                        <MDBIcon icon='bars' fas />
                    </MDBNavbarToggler>

                    <MDBCollapse navbar show={this.state.open}>

                        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-end'>
                            <MDBNavbarItem>
                                <MDBNavbarLink aria-current='page' href='/my-profile' style={{fontWeight: 'bold'}}>
                                    {this.props.i18n.t('my_profile')}
                                </MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink  active href='/learning' style={{fontWeight: 'bold'}}>{this.props.i18n.t('learning')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/users' style={{fontWeight: 'bold'}}>{this.props.i18n.t('users_list')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/' style={{fontWeight: 'bold'}}>{this.props.i18n.t('home_page_short')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/logout' style={{fontWeight: 'bold'}}>{this.props.i18n.t('sign_out')}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <div className={'mt-3'}>
                                <MDBIcon flag='uk' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('en')}/>
                                <MDBIcon flag='ir' style={{cursor: 'pointer'}} onClick={(e) => changeLangBTN('fa')}/>
                            </div>

                        </MDBNavbarNav>


                    </MDBCollapse>

                </MDBNavbar>
                <div className={'container-fluid'}>
                    <MDBRow className={'m-0'}
                            style={{
                                minHeight: '110px',
                            }}>
                        <div className={'mt-5 text-left'}>
                            <button className="btn" style={{background: '#FF686B', color: '#fff'}} onClick={(v) => this.chatBTN(v)}>
                                <label><FaMessage/> {this.props.i18n.t('conversion_btn_title')}</label>
                            </button>
                        </div>
                    </MDBRow>

                    {this.getVideoRowMobile(this.state.profile.is_seen_videos, this.props.i18n.language=== 'en')}
                    {this.getQuestionRowMobile(this.state.profile.is_seen_videos, this.state.profile.is_registration_complete, this.props.i18n.language=== 'en')}
                </div>
                </body>
            )
        }


    }

    desktopView(){
        if(this.props.i18n.language=== 'en') {
            if(this.state.isIntro == null || this.state.isIntro == false){
                return (
                    <body className="text-left bg-body ltr" dir="rtl" style={{height: "100vh", backgroundColor: '#355070'}}>
                    <div className={'container-fluid'}>
                        <RightSideNavbar
                            profile={this.state.profile}
                            page={'my-profile'}
                        />
                        <div style={{marginLeft: '250px', padding: '0px 10px',  backgroundColor: '#355070'}}>
                        <IntroPage/>
                        </div>

                    </div>
                    </body>
                )
            }
            return(
                <body className="text-right bg-body ltr" dir="ltr" style = {{height:"100vh"}}>
                <div className={'container-fluid'}>
                    <RightSideNavbar
                        profile={this.state.profile}
                        page={'learning'}
                    />

                    <div style={{marginLeft: '270px', marginRight: '70px', padding: '0px 10px'}}>
                        <MDBRow className={'m-0'} style={{minHeight: '25%'}}>
                            <div className={'mt-5 text-start'}>
                                <button className="btn" style={{background: '#FF686B', color: '#fff'}} onClick={(v) => this.chatBTN(v)}>
                                    <label><FaMessage/> {this.props.i18n.t('conversion_btn_title')}</label>
                                </button>
                            </div>
                        </MDBRow>

                        <MDBRow className={'mt-4'}>

                            {this.getVideoRow(this.state.profile.is_seen_videos, this.props.i18n.language=== 'en')}
                            {this.getQuestionRow(this.state.profile.is_seen_videos, this.state.profile.is_registration_complete, this.props.i18n.language=== 'en')}

                        </MDBRow>
                    </div>

                </div>
                </body>
            )
        }else {
            if(this.state.isIntro == null || this.state.isIntro == false){
                return (
                    <body className="text-right bg-body" dir="rtl" style = {{height:"100vh"}}>



                    <div className={'container-fluid'}>
                        <RightSideNavbar
                            profile={this.state.profile}
                            page={'my-profile'}
                        />

                        <IntroPage/>

                    </div>
                    </body>
                )
            }
            return(
                <body className="text-right bg-body" dir="rtl" style = {{height:"100vh"}}>
                <div className={'container-fluid'}>
                    <RightSideNavbar
                        profile={this.state.profile}
                        page={'learning'}
                    />

                    <div style={{marginRight: '300px', padding: '0px 10px'}}>
                        <MDBRow className={'m-0'} style={{minHeight: '25%'}}>
                            <div className={'mt-5 text-start'}>
                                <button className="btn" style={{background: '#FF686B', color: '#fff'}} onClick={(v) => this.chatBTN(v)}>
                                    <label><FaMessage/> {this.props.i18n.t('conversion_btn_title')}</label>
                                </button>
                            </div>
                        </MDBRow>

                        <MDBRow className={'mt-4'}>

                            {this.getVideoRow(this.state.profile.is_seen_videos, this.props.i18n.language=== 'en')}
                            {this.getQuestionRow(this.state.profile.is_seen_videos, this.state.profile.is_registration_complete, this.props.i18n.language=== 'en')}

                        </MDBRow>
                    </div>

                </div>
                </body>
            )
        }

    }

    render() {
        if(localStorage.getItem("token") === null){
            return(
                <div style={{fontWeight: 'bold', fontSize: '25px', direction: 'ltr'}}>
                    Forbidden!
                </div>
            );
        }

        if(this.state.profile !== null) {
            if(this.state.profile.is_verified === false){
                return(
                    <NotAllowedPage/>
                );
            }
            return (
                <div>
                    <ToastContainer/>
                    <BrowserView>
                        {this.desktopView()}
                    </BrowserView>
                    <MobileView>
                        {this.mobileView()}
                    </MobileView>
                </div>
            );
        }else {
            return(
                <div>

                </div>
            );
        }

    }

    getVideoRow(isSeen, isEn){
        if(isEn){
            if (isSeen) {
                return (
                    <MDBRow className={'bg-white p-0 m-0 mb-4'}>

                        <MDBCol size={"2"} className="justify-content-center align-items-center text-center">
                            <MDBCard style={{background: '#FFF'}} className={'h-100'}>
                                <div className="p-4 mt-4 mb-4 text-center">
                                    <img src='../../images/learning-1-icon-green.png' className={'img-fluid'}/>
                                </div>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol className="justify-content-center align-items-center">
                            <MDBCard style={{background: '#FFF'}} className={'h-100'} onClick={(v) => this.videoBTN(v)}>
                                <div className={'h-100 d-inline-block mt-4'} style={{verticalAlign: 'middle'}}>
                                    <MDBTable borderless className={'h-100'}>
                                        <MDBTableBody>
                                            <tr>
                                                <td align={'left'} style={{verticalAlign: 'middle'}}
                                                    className={'w-auto text-left'}>
                                                    <FaAngleRight
                                                        style={{color: 'black', fontWeight: 'bold', fontSize: '25px'}}/>
                                                    <label style={{fontWeight: 'bold', fontSize: '20px', color: 'black'}}>
                                                        {this.props.i18n.t('watch_video')}
                                                    </label>
                                                    <label
                                                        style={{color: 'black', fontSize: '15px', paddingRight: '5px',paddingLeft: '10px'}}>
                                                        {this.props.i18n.t('watch_video_subtitle')}
                                                    </label>
                                                </td>
                                                <td align={'center'} style={{verticalAlign: 'middle'}}
                                                    className={'w-auto text-center'}></td>
                                                <td align={'right'} style={{verticalAlign: 'middle'}}
                                                    className={'w-auto text-center'}>
                                                    <div>
                                                        <img width={'100'} src={'../../images/video_row_icon.png'}
                                                             className={'img-fluid'}/>
                                                    </div>
                                                </td>
                                            </tr>

                                        </MDBTableBody>

                                    </MDBTable>
                                </div>

                            </MDBCard>
                        </MDBCol>

                    </MDBRow>
                );
            }
            return (
                <MDBRow className={'bg-white p-0 m-0 mb-4'}>

                    <MDBCol size={"2"} className="justify-content-center align-items-center text-center">
                        <MDBCard style={{background: '#355070'}} className={'h-100'}>
                            <div className="p-4 mt-4 mb-4 text-center">
                                <img src='../../images/learning-1-icon-white.png' className={'img-fluid'}/>
                            </div>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol className="justify-content-center align-items-center">
                        <MDBCard style={{background: '#355070', cursor: 'pointer'}} className={'h-100'}
                                 onClick={(v) => this.videoBTN(v)}>
                            <div className={'h-100 d-inline-block mt-4'} style={{verticalAlign: 'middle'}}>
                                <MDBTable borderless className={'h-100'}>
                                    <MDBTableBody>
                                        <tr>
                                            <td align={'left'} style={{verticalAlign: 'middle'}}
                                                className={'w-auto text-left'}>
                                                <FaAngleRight style={{
                                                    color: 'white',
                                                    fontWeight: 'bold',
                                                    fontSize: '25px'
                                                }}/><label
                                                style={{fontWeight: 'bold', fontSize: '25px', color: 'white'}}>
                                                {this.props.i18n.t('watch_video')}
                                            </label>
                                                <label style={{color: 'white', fontSize: '15px', paddingRight: '5px', paddingLeft: '10px'}}>
                                                    {this.props.i18n.t('watch_video_subtitle')}
                                                </label>
                                            </td>
                                            <td align={'center'} style={{verticalAlign: 'middle'}}
                                                className={'w-auto text-center'}></td>
                                            <td align={'right'} style={{verticalAlign: 'middle'}}
                                                className={'w-auto text-right'}>
                                                <div>
                                                    <img width={'100'} src={'../../images/video_row_icon.png'}
                                                         className={'img-fluid'}/>
                                                </div>
                                            </td>
                                        </tr>

                                    </MDBTableBody>

                                </MDBTable>
                            </div>

                        </MDBCard>
                    </MDBCol>

                </MDBRow>
            );
        }else {
            if (isSeen) {
                return (
                    <MDBRow className={'bg-white p-0 m-0 mb-4'}>

                        <MDBCol size={"2"} className="justify-content-center align-items-center text-center">
                            <MDBCard style={{background: '#FFF'}} className={'h-100'}>
                                <div className="p-4 mt-4 mb-4 text-center">
                                    <img src='../../images/learning-1-icon-green.png' className={'img-fluid'}/>
                                </div>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol className="justify-content-center align-items-center">
                            <MDBCard style={{background: '#FFF'}} className={'h-100'} onClick={(v) => this.videoBTN(v)}>
                                <div className={'h-100 d-inline-block mt-4'} style={{verticalAlign: 'middle'}}>
                                    <MDBTable borderless className={'h-100'}>
                                        <MDBTableBody>
                                            <tr>
                                                <td align={'right'} style={{verticalAlign: 'middle'}}
                                                    className={'w-auto text-right'}>
                                                    <FaAngleLeft
                                                        style={{color: 'black', fontWeight: 'bold', fontSize: '25px'}}/><label
                                                    style={{fontWeight: 'bold', fontSize: '25px', color: 'black'}}>
                                                    {this.props.i18n.t('watch_video')}
                                                </label>
                                                    <label
                                                        style={{color: 'black', fontSize: '15px', paddingRight: '10px'}}>
                                                        {this.props.i18n.t('watch_video_subtitle')}
                                                    </label>
                                                </td>
                                                <td align={'center'} style={{verticalAlign: 'middle'}}
                                                    className={'w-auto text-center'}></td>
                                                <td align={'center'} style={{verticalAlign: 'middle'}}
                                                    className={'w-auto text-center'}>
                                                    <div>
                                                        <img width={'100'} src={'../../images/video_row_icon.png'}
                                                             className={'img-fluid'}/>
                                                    </div>
                                                </td>
                                            </tr>

                                        </MDBTableBody>

                                    </MDBTable>
                                </div>

                            </MDBCard>
                        </MDBCol>

                    </MDBRow>
                );
            }
            return (
                <MDBRow className={'bg-white p-0 m-0 mb-4'}>

                    <MDBCol size={"2"} className="justify-content-center align-items-center text-center">
                        <MDBCard style={{background: '#355070'}} className={'h-100'}>
                            <div className="p-4 mt-4 mb-4 text-center">
                                <img src='../../images/learning-1-icon-white.png' className={'img-fluid'}/>
                            </div>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol className="justify-content-center align-items-center">
                        <MDBCard style={{background: '#355070', cursor: 'pointer'}} className={'h-100'}
                                 onClick={(v) => this.videoBTN(v)}>
                            <div className={'h-100 d-inline-block mt-4'} style={{verticalAlign: 'middle'}}>
                                <MDBTable borderless className={'h-100'}>
                                    <MDBTableBody>
                                        <tr>
                                            <td align={'center'} style={{verticalAlign: 'middle'}}
                                                className={'w-auto text-right'}>
                                                <FaAngleLeft style={{
                                                    color: 'white',
                                                    fontWeight: 'bold',
                                                    fontSize: '25px'
                                                }}/><label
                                                style={{fontWeight: 'bold', fontSize: '25px', color: 'white'}}>
                                                {this.props.i18n.t('watch_video')}
                                            </label>
                                                <label style={{color: 'white', fontSize: '15px', paddingRight: '10px'}}>
                                                    {this.props.i18n.t('watch_video_subtitle')}
                                                </label>
                                            </td>
                                            <td align={'center'} style={{verticalAlign: 'middle'}}
                                                className={'w-auto text-center'}></td>
                                            <td align={'center'} style={{verticalAlign: 'middle'}}
                                                className={'w-auto text-center'}>
                                                <div>
                                                    <img width={'100'} src={'../../images/video_row_icon.png'}
                                                         className={'img-fluid'}/>
                                                </div>
                                            </td>
                                        </tr>

                                    </MDBTableBody>

                                </MDBTable>
                            </div>

                        </MDBCard>
                    </MDBCol>

                </MDBRow>
            );
        }
    }

    getQuestionRow(isSeenVideos, isAnsweredQuestions, isEn){

        if(isEn){
            if (isSeenVideos && !isAnsweredQuestions) {
                return (
                    <MDBRow className={'bg-white p-0 m-0'}>

                        <MDBCol size={"2"} className="justify-content-center align-items-center text-center">
                            <MDBCard style={{background: '#355070', cursor: 'pointer'}} className={'h-100'}>
                                <div className="p-4 mt-4 mb-4 text-center">
                                    <img src='../../images/learning-2-icon-white.png' className={'img-fluid'}/>
                                </div>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol className="justify-content-center align-items-center">
                            <MDBCard style={{background: '#355070', cursor: 'pointer'}} className={'h-100'}
                                     onClick={(v) => this.questionBTN(true)}>
                                <div className={'h-100 d-inline-block mt-4'} style={{verticalAlign: 'middle'}}>
                                    <MDBTable borderless className={'h-100'}>
                                        <MDBTableBody>
                                            <tr>
                                                <td align={'left'} style={{verticalAlign: 'middle'}}
                                                    className={'w-auto text-left'}>
                                                    <FaAngleRight
                                                        style={{color: 'white', fontWeight: 'bold', fontSize: '25px'}}/><label
                                                    style={{fontWeight: 'bold', fontSize: '25px', color: 'white'}}>
                                                    {this.props.i18n.t('questions')}
                                                </label>

                                                </td>
                                                <td align={'center'} style={{verticalAlign: 'middle'}}
                                                    className={'w-auto text-center'}></td>
                                                <td align={'right'} style={{verticalAlign: 'middle'}}
                                                    className={'w-auto text-right'}>
                                                    <div>
                                                        <img width={'100'} src={'../../images/question_row_icon.png'}
                                                             className={'img-fluid'}/>
                                                    </div>
                                                </td>
                                            </tr>

                                        </MDBTableBody>

                                    </MDBTable>
                                </div>

                            </MDBCard>
                        </MDBCol>

                    </MDBRow>
                );
            } else {
                if (isSeenVideos && isAnsweredQuestions) {
                    return (
                        <MDBRow className={'bg-white p-0 m-0'}>

                            <MDBCol size={"2"} className="justify-content-center align-items-center text-center">
                                <MDBCard style={{background: '#FFFFFF'}} className={'h-100'}>
                                    <div className="p-4 mt-4 mb-4 text-center">
                                        <img src='../../images/learning-2-icon-green.png' className={'img-fluid'}/>
                                    </div>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol className="justify-content-center align-items-center">
                                <MDBCard style={{background: '#FFFFFF'}} className={'h-100'}
                                         onClick={(v) => this.questionBTN(false)}>
                                    <div className={'h-100 d-inline-block mt-4'} style={{verticalAlign: 'middle'}}>
                                        <MDBTable borderless className={'h-100'}>
                                            <MDBTableBody>
                                                <tr>
                                                    <td align={'left'} style={{verticalAlign: 'middle'}}
                                                        className={'w-auto text-left'}>
                                                        <FaAngleRight style={{
                                                            color: 'black',
                                                            fontWeight: 'bold',
                                                            fontSize: '25px'
                                                        }}/>
                                                        <label style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '25px',
                                                            color: 'black'
                                                        }}>
                                                            {this.props.i18n.t('questions')}
                                                        </label>

                                                    </td>
                                                    <td align={'center'} style={{verticalAlign: 'middle'}}
                                                        className={'w-auto text-center'}></td>
                                                    <td align={'right'} style={{verticalAlign: 'middle'}}
                                                        className={'w-auto text-right'}>
                                                        <div >
                                                            <img width={'100'}
                                                                 src={'../../images/question_row_icon.png'}
                                                                 className={'img-fluid'}/>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </MDBTableBody>

                                        </MDBTable>
                                    </div>

                                </MDBCard>
                            </MDBCol>

                        </MDBRow>
                    );
                }
                return(
                    <MDBRow className={'bg-white p-0 m-0'} >

                        <MDBCol size={"2"} className="justify-content-center align-items-center text-center">
                            <MDBCard style={{background: '#FFFFFF'}} className={'h-100'}>
                                <div className="p-4 mt-4 mb-4 text-center">
                                    <img src='../../images/learning-2-icon-grey.png' className={'img-fluid'}/>
                                </div>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol className="justify-content-center align-items-center">
                            <MDBCard style={{background: '#FFFFFF'}} className={'h-100'} onClick={(v) => this.questionBTN(false)}>
                                <div className={'h-100 d-inline-block mt-4'} style={{verticalAlign: 'middle'}}>
                                    <MDBTable borderless className={'h-100'}>
                                        <MDBTableBody>
                                            <tr>
                                                <td align={'left'} style={{verticalAlign: 'middle'}} className={'w-auto text-left'}>
                                                    <FaAngleRight style={{color: 'E1E1E1', fontWeight: 'bold', fontSize: '25px'}}/><label style={{fontWeight: 'bold', fontSize: '25px', color: '#E1E1E1'}}>
                                                    {this.props.i18n.t('questions')}
                                                </label>

                                                </td>
                                                <td align={'center'} style={{verticalAlign: 'middle'}} className={'w-auto text-center'}></td>
                                                <td align={'right'} style={{verticalAlign: 'middle'}} className={'w-auto text-right'}>
                                                    <div>
                                                        <img width={'100'} src={'../../images/question_row_icon-grey.png'} className={'img-fluid'}/>
                                                    </div>
                                                </td>
                                            </tr>

                                        </MDBTableBody>

                                    </MDBTable>
                                </div>

                            </MDBCard>
                        </MDBCol>

                    </MDBRow>
                );
            }
        }else {
            if (isSeenVideos && !isAnsweredQuestions) {
                return (
                    <MDBRow className={'bg-white p-0 m-0'}>

                        <MDBCol size={"2"} className="justify-content-center align-items-center text-center">
                            <MDBCard style={{background: '#355070', cursor: 'pointer'}} className={'h-100'}>
                                <div className="p-4 mt-4 mb-4 text-center">
                                    <img src='../../images/learning-2-icon-white.png' className={'img-fluid'}/>
                                </div>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol className="justify-content-center align-items-center">
                            <MDBCard style={{background: '#355070', cursor: 'pointer'}} className={'h-100'}
                                     onClick={(v) => this.questionBTN(true)}>
                                <div className={'h-100 d-inline-block mt-4'} style={{verticalAlign: 'middle'}}>
                                    <MDBTable borderless className={'h-100'}>
                                        <MDBTableBody>
                                            <tr>
                                                <td align={'right'} style={{verticalAlign: 'middle'}}
                                                    className={'w-auto text-right'}>
                                                    <FaAngleLeft
                                                        style={{color: 'white', fontWeight: 'bold', fontSize: '25px'}}/><label
                                                    style={{fontWeight: 'bold', fontSize: '25px', color: 'white'}}>
                                                    {this.props.i18n.t('questions')}
                                                </label>

                                                </td>
                                                <td align={'center'} style={{verticalAlign: 'middle'}}
                                                    className={'w-auto text-center'}></td>
                                                <td align={'left'} style={{verticalAlign: 'middle'}}
                                                    className={'w-auto text-left'}>
                                                    <div>
                                                        <img width={'100'} src={'../../images/question_row_icon.png'}
                                                             className={'img-fluid'}/>
                                                    </div>
                                                </td>
                                            </tr>

                                        </MDBTableBody>

                                    </MDBTable>
                                </div>

                            </MDBCard>
                        </MDBCol>

                    </MDBRow>
                );
            } else {
                if (isSeenVideos && isAnsweredQuestions) {
                    return (
                        <MDBRow className={'bg-white p-0 m-0'}>

                            <MDBCol size={"2"} className="justify-content-center align-items-center text-center">
                                <MDBCard style={{background: '#FFFFFF'}} className={'h-100'}>
                                    <div className="p-4 mt-4 mb-4 text-center">
                                        <img src='../../images/learning-2-icon-green.png' className={'img-fluid'}/>
                                    </div>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol className="justify-content-center align-items-center">
                                <MDBCard style={{background: '#FFFFFF'}} className={'h-100'}
                                         onClick={(v) => this.questionBTN(false)}>
                                    <div className={'h-100 d-inline-block mt-4'} style={{verticalAlign: 'middle'}}>
                                        <MDBTable borderless className={'h-100'}>
                                            <MDBTableBody>
                                                <tr>
                                                    <td align={'right'} style={{verticalAlign: 'middle'}}
                                                        className={'w-auto text-right'}>
                                                        <FaAngleLeft style={{
                                                            color: 'black',
                                                            fontWeight: 'bold',
                                                            fontSize: '25px'
                                                        }}/>
                                                        <label style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '25px',
                                                            color: 'black'
                                                        }}>
                                                            {this.props.i18n.t('questions')}
                                                        </label>

                                                    </td>
                                                    <td align={'center'} style={{verticalAlign: 'middle'}}
                                                        className={'w-auto text-center'}></td>
                                                    <td align={'left'} style={{verticalAlign: 'middle'}}
                                                        className={'w-auto text-left'}>
                                                        <div>
                                                            <img width={'100'}
                                                                 src={'../../images/question_row_icon.png'}
                                                                 className={'img-fluid'}/>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </MDBTableBody>

                                        </MDBTable>
                                    </div>

                                </MDBCard>
                            </MDBCol>

                        </MDBRow>
                    );
                }
                return(
                    <MDBRow className={'bg-white p-0 m-0'} >

                        <MDBCol size={"2"} className="justify-content-center align-items-center text-center">
                            <MDBCard style={{background: '#FFFFFF'}} className={'h-100'}>
                                <div className="p-4 mt-4 mb-4 text-center">
                                    <img src='../../images/learning-2-icon-grey.png' className={'img-fluid'}/>
                                </div>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol className="justify-content-center align-items-center">
                            <MDBCard style={{background: '#FFFFFF'}} className={'h-100'} onClick={(v) => this.questionBTN(false)}>
                                <div className={'h-100 d-inline-block mt-4'} style={{verticalAlign: 'middle'}}>
                                    <MDBTable borderless className={'h-100'}>
                                        <MDBTableBody>
                                            <tr>
                                                <td align={'right'} style={{verticalAlign: 'middle'}} className={'w-auto text-right'}>
                                                    <FaAngleLeft style={{color: 'E1E1E1', fontWeight: 'bold', fontSize: '25px'}}/><label style={{fontWeight: 'bold', fontSize: '25px', color: '#E1E1E1'}}>
                                                    {this.props.i18n.t('questions')}
                                                </label>

                                                </td>
                                                <td align={'center'} style={{verticalAlign: 'middle'}} className={'w-auto text-center'}></td>
                                                <td align={'left'} style={{verticalAlign: 'middle'}} className={'w-auto text-left'}>
                                                    <div>
                                                        <img width={'100'} src={'../../images/question_row_icon-grey.png'} className={'img-fluid'}/>
                                                    </div>
                                                </td>
                                            </tr>

                                        </MDBTableBody>

                                    </MDBTable>
                                </div>

                            </MDBCard>
                        </MDBCol>

                    </MDBRow>
                );
            }
        }


    }

    getVideoRowMobile(isSeen, isEn){
        if(isEn){
            if(isSeen){
                return(
                    <MDBRow className={'bg-white p-4 mt-4 mb-4 text-left ltr'} >
                        <MDBCard style={{background: '#FFF'}} className={'h-100'} onClick={(v) => this.videoBTN(v)}>
                            <div className={'h-100 d-inline-block mt-4 mb-4'} style={{verticalAlign: 'middle', marginLeft: '20px'}}>
                                <FaCheckCircle style={{color: 'green', fontWeight: 'bold', fontSize: '15px', marginRight: '5px'}}/>
                                <label style={{fontWeight: 'bold', fontSize: '15px', color: 'black'}}>
                                    {this.props.i18n.t('watch_video')}
                                </label>
                                <label style={{color: 'black', fontSize: '15px', paddingRight: '5px'}}>
                                    {this.props.i18n.t('watch_video_msg')}
                                </label>

                            </div>

                        </MDBCard>
                    </MDBRow>
                );
            }
            return(
                <MDBRow className={'bg-white p-4 mt-4 mb-4 text-left ltr'} >

                    <MDBCard style={{background: '#355070'}} className={'h-100'} onClick={(v) => this.videoBTN(v)}>
                        <div className={'h-100 d-inline-block mt-4 mb-4'} style={{verticalAlign: 'middle', marginLeft: '20px'}}>

                            <FaAngleRight style={{color: 'white', fontWeight: 'bold', fontSize: '15px'}}/><label style={{fontWeight: 'bold', fontSize: '15px', color: 'white'}}>
                            {this.props.i18n.t('watch_video')}
                        </label>
                            <label style={{color: 'white', fontSize: '15px', paddingRight: '5px'}}>
                                {this.props.i18n.t('watch_video_subtitle')}
                            </label>

                        </div>
                    </MDBCard>
                </MDBRow>
            );
        }else {
            if (isSeen) {
                return (
                    <MDBRow className={'bg-white p-4 mt-4 mb-4 text-right'}>
                        <MDBCard style={{background: '#FFF'}} className={'h-100'} onClick={(v) => this.videoBTN(v)}>
                            <div className={'h-100 d-inline-block mt-4 mb-4'}
                                 style={{verticalAlign: 'middle', marginRight: '20px'}}>
                                <FaCheckCircle
                                    style={{color: 'green', fontWeight: 'bold', fontSize: '15px', marginLeft: '5px'}}/>
                                <label style={{fontWeight: 'bold', fontSize: '15px', color: 'black'}}>
                                    {this.props.i18n.t('watch_video')}
                                </label>
                                <label style={{color: 'black', fontSize: '15px', paddingRight: '5px'}}>
                                    {this.props.i18n.t('watch_video_msg')}
                                </label>

                            </div>

                        </MDBCard>
                    </MDBRow>
                );
            }
            return (
                <MDBRow className={'bg-white p-4 mt-4 mb-4 text-right'}>

                    <MDBCard style={{background: '#355070'}} className={'h-100'} onClick={(v) => this.videoBTN(v)}>
                        <div className={'h-100 d-inline-block mt-4 mb-4'}
                             style={{verticalAlign: 'middle', marginRight: '20px'}}>

                            <FaAngleLeft style={{color: 'white', fontWeight: 'bold', fontSize: '15px'}}/><label
                            style={{fontWeight: 'bold', fontSize: '15px', color: 'white'}}>
                            {this.props.i18n.t('watch_video')}
                        </label>
                            <label style={{color: 'white', fontSize: '15px', paddingRight: '5px'}}>
                                {this.props.i18n.t('watch_video_subtitle')}
                            </label>

                        </div>

                    </MDBCard>


                </MDBRow>
            );
        }
    }

    getQuestionRowMobile(isSeenVideos, isAnsweredQuestions, isEn){
        if(isEn){
            if(isSeenVideos && !isAnsweredQuestions){
                return (
                    <MDBRow className={'bg-white p-4 mt-4 mb-4 text-left ltr'} >

                        <MDBCard style={{background: '#355070'}} className={'h-100'} onClick={(v) => this.questionBTN(true)}>
                            <div className={'h-100 d-inline-block mt-4'} style={{verticalAlign: 'middle'}}>
                                <MDBTable borderless className={'h-100'}>
                                    <MDBTableBody>
                                        <tr >
                                            <td align={'left'} style={{verticalAlign: 'middle'}} className={'w-auto text-left'}>
                                                <FaAngleRight style={{color: 'white', fontWeight: 'bold', fontSize: '15px'}}/>
                                                <label style={{fontWeight: 'bold', fontSize: '15px', color: 'white'}}>
                                                    {this.props.i18n.t('questions')}
                                                </label>

                                            </td>
                                            <td align={'center'} style={{verticalAlign: 'middle'}} className={'w-auto text-center'}></td>

                                        </tr>

                                    </MDBTableBody>

                                </MDBTable>
                            </div>

                        </MDBCard>


                    </MDBRow>
                );
            }else {
                if(isSeenVideos && isAnsweredQuestions){
                    return(
                        <MDBRow className={'bg-white p-4 mt-4 mb-4 text-left ltr'} >

                            <MDBCard style={{background: '#FFFFFF'}} className={'h-100'} onClick={(v) => this.questionBTN(false)}>
                                <div className={'h-100 d-inline-block mt-4'} style={{verticalAlign: 'middle'}}>
                                    <MDBTable borderless className={'h-100 text-left'}>
                                        <MDBTableBody>
                                            <tr>
                                                <td align={'left'} style={{verticalAlign: 'middle'}} className={'w-auto text-left'}>
                                                    <FaCheckCircle style={{color: 'green', fontWeight: 'bold', fontSize: '15px', marginRight: '5px'}}/>
                                                    <label style={{fontWeight: 'bold', fontSize: '15px', color: 'black'}}>
                                                        {this.props.i18n.t('questions')}
                                                    </label>

                                                </td>
                                                <td align={'center'} style={{verticalAlign: 'middle'}} className={'w-auto text-center'}></td>

                                            </tr>

                                        </MDBTableBody>

                                    </MDBTable>
                                </div>

                            </MDBCard>

                        </MDBRow>
                    );
                }
            }
            return(
                <MDBRow className={'bg-white p-4 mt-4 mb-4 text-left ltr'} >

                    <MDBCard style={{background: '#FFFFFF'}} className={'h-100'} onClick={(v) => this.questionBTN(false)}>
                        <div className={'h-100 d-inline-block mt-4'} style={{verticalAlign: 'middle'}}>
                            <MDBTable borderless className={'h-100'}>
                                <MDBTableBody>
                                    <tr>
                                        <td align={'left'} style={{verticalAlign: 'middle'}} className={'w-auto text-left'}>
                                            <FaAngleRight style={{color: 'E1E1E1', fontWeight: 'bold', fontSize: '15px'}}/><label style={{fontWeight: 'bold', fontSize: '15px', color: '#E1E1E1'}}>
                                            {this.props.i18n.t('questions')}
                                        </label>

                                        </td>
                                        <td align={'center'} style={{verticalAlign: 'middle'}} className={'w-auto text-center'}></td>

                                    </tr>

                                </MDBTableBody>

                            </MDBTable>
                        </div>

                    </MDBCard>

                </MDBRow>
            );
        }else {
            if(isSeenVideos && !isAnsweredQuestions){
                return (
                    <MDBRow className={'bg-white p-4 mt-4 mb-4 text-center'} >

                        <MDBCard style={{background: '#355070'}} className={'h-100'} onClick={(v) => this.questionBTN(true)}>
                            <div className={'h-100 d-inline-block mt-4'} style={{verticalAlign: 'middle'}}>
                                <MDBTable borderless className={'h-100'}>
                                    <MDBTableBody>
                                        <tr >
                                            <td align={'right'} style={{verticalAlign: 'middle'}} className={'w-auto text-right'}>
                                                <FaAngleLeft style={{color: 'white', fontWeight: 'bold', fontSize: '15px'}}/>
                                                <label style={{fontWeight: 'bold', fontSize: '15px', color: 'white'}}>
                                                    {this.props.i18n.t('questions')}
                                                </label>

                                            </td>
                                            <td align={'center'} style={{verticalAlign: 'middle'}} className={'w-auto text-center'}></td>

                                        </tr>

                                    </MDBTableBody>

                                </MDBTable>
                            </div>

                        </MDBCard>


                    </MDBRow>
                );
            }else {
                if(isSeenVideos && isAnsweredQuestions){
                    return(
                        <MDBRow className={'bg-white p-4 mt-4 mb-4 text-right'} >

                            <MDBCard style={{background: '#FFFFFF'}} className={'h-100'} onClick={(v) => this.questionBTN(false)}>
                                <div className={'h-100 d-inline-block mt-4'} style={{verticalAlign: 'middle'}}>
                                    <MDBTable borderless className={'h-100 text-right'}>
                                        <MDBTableBody>
                                            <tr>
                                                <td align={'right'} style={{verticalAlign: 'middle'}} className={'w-auto text-right'}>
                                                    <FaCheckCircle style={{color: 'green', fontWeight: 'bold', fontSize: '15px', marginLeft: '5px'}}/>
                                                    <label style={{fontWeight: 'bold', fontSize: '15px', color: 'black'}}>
                                                        {this.props.i18n.t('questions')}
                                                    </label>

                                                </td>
                                                <td align={'center'} style={{verticalAlign: 'middle'}} className={'w-auto text-center'}></td>

                                            </tr>

                                        </MDBTableBody>

                                    </MDBTable>
                                </div>

                            </MDBCard>

                        </MDBRow>
                    );
                }
            }
            return(
                <MDBRow className={'bg-white p-4 mt-4 mb-4 text-center'} >

                    <MDBCard style={{background: '#FFFFFF'}} className={'h-100'} onClick={(v) => this.questionBTN(false)}>
                        <div className={'h-100 d-inline-block mt-4'} style={{verticalAlign: 'middle'}}>
                            <MDBTable borderless className={'h-100'}>
                                <MDBTableBody>
                                    <tr>
                                        <td align={'right'} style={{verticalAlign: 'middle'}} className={'w-auto text-right'}>
                                            <FaAngleLeft style={{color: 'E1E1E1', fontWeight: 'bold', fontSize: '15px'}}/><label style={{fontWeight: 'bold', fontSize: '15px', color: '#E1E1E1'}}>
                                            {this.props.i18n.t('questions')}
                                        </label>

                                        </td>
                                        <td align={'center'} style={{verticalAlign: 'middle'}} className={'w-auto text-center'}></td>

                                    </tr>

                                </MDBTableBody>

                            </MDBTable>
                        </div>

                    </MDBCard>

                </MDBRow>
            );
        }


    }

    chatBTN(st){
        window.location.href = '/chatroom'
    }

    videoBTN(st){
        window.location.href = '/learning/videos'
    }

    questionBTN(isEnable){
        if(isEnable){
            window.location.href = '/learning/questions'

        }else {

        }

    }
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

export default connect()(withParams(withTranslation()(LearningPage)));
