import React, {Component} from "react";
import {MDBCol, MDBRow} from "mdbreact";
import {FaArrowLeft, FaArrowRight} from "react-icons/fa";
import {ToastContainer} from "react-toastify";
import {BrowserView, MobileView} from "react-device-detect";
import {withTranslation} from "react-i18next";
import {LanguageContext} from "../LanguageContext";


class IntroPage extends Component{

    static contextType = LanguageContext;

    constructor() {
        super();

        this.state = {
            state: 1,
            isConfirm: false
        }
    }

    chooseLang() {
        var l = localStorage.getItem("lang")
        this.languageChangeListener = () =>{
            var lang = localStorage.getItem("lang")

        }

        this.props.i18n.on('languageChanged', this.languageChangeListener);

        if (l !== null) {
            const {setLanguage} = this.context;
            setLanguage(l)

            this.props.i18n.changeLanguage(l);
        }
    }

    componentDidMount() {
        this.chooseLang()
    }

    desktopView(){
        if(this.props.i18n.language === 'en'){
            return(
                <div className={'text-start d-flex'} style={{
                    backgroundColor: '#355070',
                    height: '100vh',
                    overflowY: 'auto',
                    width:'88%',
                    padding: '0px 10px'
                }}>

                    <div style={{marginLeft: '10%'}}>

                        {this.getStep(false, false)}
                    </div>
                </div>
            )
        }else {
            return(
                <div className={'text-start d-flex'} style={{
                    backgroundColor: '#355070',
                    height: '100vh',
                    overflowY: 'auto',
                    width:'88%',
                    padding: '0px 10px'
                }}>
                    <div style={{marginRight: '40%'}}>

                        {this.getStep(false, true)}
                    </div>
                </div>
            )
        }

    }

    mobileView(){
        if(this.props.i18n.language === 'en'){
            return (
                <div className={'container-fluid'} style={{backgroundColor: '#355070', height: '100%'}}>

                    {this.getStep(true, false)}

                </div>
            )
        }else {
            return (
                <div className={'container-fluid'} style={{backgroundColor: '#355070', height: '100%'}}>

                    {this.getStep(true, true)}

                </div>
            )
        }
    }
    render() {
        return (
            <div style={{backgroundColor: '#355070',}}>
                <ToastContainer/>
                <BrowserView>
                    {this.desktopView()}
                </BrowserView>
                <MobileView>
                    {this.mobileView()}
                </MobileView>
            </div>
        );

    }

    getStep(isMobile, isRTL){
        if(isRTL){
            if(isMobile){
                if (this.state.state === 1) {
                    return (
                        <MDBRow className="justify-content-center rtl align-items-center h-100 text-center">
                            <div className={'mt-4'}>
                                <img src='../../images/intro-1.png' className={'img-fluid'}/>
                            </div>
                            <div className={'m-3'}>
                                <p style={{
                                    color: 'white',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    fontSize: '15px',
                                    direction: 'rtl'
                                }}>
                                    {this.props.i18n.t('welcome_to_msg_intro')}
                                </p>
                                <p style={{
                                    color: 'white',
                                    direction: 'rtl',
                                    textAlign: 'center'}}>
                                    {this.props.i18n.t('welcome_to_msg2')}
                                </p>
                            </div>

                            <MDBCol>
                                <div className={'text-right p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        direction: 'rtl',
                                        borderRadius: '5px',

                                    }} onClick={(v) => this.nextPage(v)}>
                                        <label><FaArrowRight style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}</label>
                                    </button>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    )
                }

                if (this.state.state === 2) {
                    return (
                        <MDBRow className="justify-content-center align-items-center h-100 text-center">
                            <div className={'mt-4'}>
                                <img src='../../images/intro-2.png' className={'img-fluid'}/>
                            </div>
                            <div className={'m-3'}>
                                <p style={{
                                    marginTop: '20px',
                                    color: 'white',
                                    fontSize: '15px',
                                    textAlign: 'justify'}}>
                                    {this.props.i18n.t('welcome_second_msg')}
                                </p>
                            </div>

                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-right p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        borderRadius: '5px',
                                    }} onClick={(v) => this.nextPage(v)}>
                                        <label><FaArrowRight style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}</label>
                                    </button>
                                </div>
                            </MDBCol>
                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-left p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        borderRadius: '5px',
                                    }} onClick={(v) => this.previousPage(v)}>
                                        <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft style={{marginRight: '4px'}}/></label>
                                    </button>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    )
                }

                if (this.state.state === 3) {
                    return (
                        <MDBRow className="justify-content-center align-items-center h-100 text-center">
                            <div className={'mt-4'}>
                                <img src='../../images/intro-3.png' className={'img-fluid'}/>
                            </div>
                            <div className={'m-3'}>
                                <p style={{
                                    color: 'white',
                                    marginTop: '20px',
                                    fontSize: '15px',
                                    textAlign: 'center'}}>
                                    {this.props.i18n.t('welcome_third_msg')}
                                </p>
                            </div>

                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-right p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        borderRadius: '5px',
                                    }} onClick={(v) => this.nextPage(v)}>
                                        <label><FaArrowRight style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}</label>
                                    </button>
                                </div>
                            </MDBCol>
                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-left p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        borderRadius: '5px',
                                    }} onClick={(v) => this.previousPage(v)}>
                                        <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft style={{marginRight: '4px'}}/></label>
                                    </button>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    )
                }

                if (this.state.state === 4) {
                    return (
                        <MDBRow className="justify-content-center align-items-center h-100 text-center">
                            <div className={'mt-4'}>
                                <img src='../../images/intro-end.png' className={'img-fluid'}/>
                            </div>
                            <div className={'m-3'}>
                                <p style={{
                                    color: 'white',
                                    textAlign: 'center'}}>
                                    {this.props.i18n.t('welcome_fourth_msg')}
                                </p>
                            </div>

                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-left p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        borderRadius: '5px',
                                    }} onClick={(v) => this.confirmBTN(v)}>
                                        <label style={{marginLeft: '4px', marginRight: '4px'}}>{this.props.i18n.t('welcome_ok_btn')}</label>
                                    </button>
                                </div>
                            </MDBCol>
                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-left p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        borderRadius: '5px',
                                    }} onClick={(v) => this.previousPage(v)}>
                                        <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft style={{marginRight: '4px'}}/></label>
                                    </button>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    )
                }
            }else {
                if (this.state.state === 1) {
                    return (
                        <MDBRow className="justify-content-center align-items-center h-100 text-center">
                            <div className={'text-center'}>
                                <MDBRow className="justify-content-center align-items-center h-100 text-center m-1" >
                                    <MDBCol sm={"6"}>
                                        <img  src='../../images/intro-1.png' className={'img-fluid'}/>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                            <div className={'m-0'}>
                                <p style={{
                                    color: 'white',
                                    paddingRight: '70px',
                                    paddingLeft: '70px',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    fontSize: '30px',
                                    direction: 'rtl',
                                }}>
                                    {this.props.i18n.t('welcome_to_msg_intro')}
                                </p>
                                <p style={{color: 'white', direction: 'rtl', paddingRight: '70px',
                                    paddingLeft: '70px', textAlign: 'center'}}>
                                    {this.props.i18n.t('welcome_to_msg2')}
                                </p>
                            </div>

                            <MDBCol>
                                <div className={'text-right p-5'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        borderRadius: '5px',
                                        width: '10%'
                                    }} onClick={(v) => this.nextPage(v)}>
                                        <label><FaArrowRight style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}</label>
                                    </button>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    )
                }

                if (this.state.state === 2) {
                    return (
                        <MDBRow className="justify-content-center align-items-center h-100 text-center">
                            <div style={{marginLeft: '10%'}}>
                                <MDBRow className="justify-content-center align-items-center h-100 text-center m-1" >
                                    <MDBCol sm={"6"}>
                                        <img src='../../images/intro-2.png' className={'img-fluid'}/>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                            <div className={'m-0'}>
                                <p style={{color: 'white',
                                    paddingRight: '70px',
                                    // paddingLeft: '70px',
                                    // marginLeft: '10%',
                                    textAlign: 'center'}}>
                                    {this.props.i18n.t('welcome_second_msg')}
                                </p>
                            </div>

                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-right p-5'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        borderRadius: '5px',
                                        width: '25%'
                                    }} onClick={(v) => this.nextPage(v)}>
                                        <label><FaArrowRight style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}</label>
                                    </button>
                                </div>
                            </MDBCol>
                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-left p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        borderRadius: '5px',
                                        width: '25%'
                                    }} onClick={(v) => this.previousPage(v)}>
                                        <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft style={{marginRight: '4px'}}/></label>
                                    </button>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    )
                }

                if (this.state.state === 3) {
                    return (
                        <MDBRow className="justify-content-center align-items-center h-100 text-center">
                            <div style={{marginLeft: '10%'}}>
                                <MDBRow className="justify-content-center align-items-center h-100 text-center m-1" >
                                    <MDBCol sm={"6"}>
                                <img src='../../images/intro-3.png' className={'img-fluid'}/>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                            <div className={'m-0'}>
                                <p style={{color: 'white',
                                    marginLeft: '10%',
                                    paddingRight: '70px', paddingLeft: '70px', textAlign: 'center'}}>
                                    {this.props.i18n.t('welcome_third_msg')}
                                </p>
                            </div>

                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-right p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        borderRadius: '5px',
                                        width: '25%'
                                    }} onClick={(v) => this.nextPage(v)}>
                                        <label><FaArrowRight style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}</label>
                                    </button>
                                </div>
                            </MDBCol>
                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-left p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        borderRadius: '5px',
                                        width: '25%'
                                    }} onClick={(v) => this.previousPage(v)}>
                                        <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft style={{marginRight: '4px'}}/></label>
                                    </button>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    )
                }

                if (this.state.state === 4) {
                    return (
                        <MDBRow className="justify-content-center align-items-center h-100 text-center">
                            <div>
                                <MDBRow className="justify-content-center align-items-center h-100 text-center m-1" >
                                    <MDBCol sm={"6"}>
                                <img src='../../images/intro-end.png' className={'img-fluid'}/>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                            <div className={'m-0'}>
                                <p style={{color: 'white', paddingRight: '70px', paddingLeft: '70px', textAlign: 'center'}}>
                                    {this.props.i18n.t('welcome_fourth_msg')}
                                </p>
                            </div>

                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-right p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        borderRadius: '5px',
                                        width: '25%'
                                    }} onClick={(v) => this.confirmBTN(v)}>
                                        <label style={{marginLeft: '4px', marginRight: '4px'}}>{this.props.i18n.t('welcome_ok_btn')}</label>
                                    </button>
                                </div>
                            </MDBCol>
                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-left p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        borderRadius: '5px',
                                        width: '25%'
                                    }} onClick={(v) => this.previousPage(v)}>
                                        <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft style={{marginRight: '4px'}}/></label>
                                    </button>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    )
                }
            }
        }else {
            if(isMobile){
                if (this.state.state === 1) {
                    return (
                        <MDBRow className="justify-content-center align-items-center h-100 text-center">
                            <div className={'mt-4'}>
                                <img src='../../images/intro-1.png' className={'img-fluid'}/>
                            </div>
                            <div className={'m-3 '}>
                                <p style={{
                                    color: 'white',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    fontSize: '15px',
                                    direction: 'ltr'
                                }}>
                                    {this.props.i18n.t('welcome_to_msg_intro')}
                                </p>
                                <p style={{
                                    direction: 'ltr',
                                    color: 'white',
                                    textAlign: 'center'}}>
                                    {this.props.i18n.t('welcome_to_msg2')}
                                </p>
                            </div>

                            <MDBCol>
                                <div className={'text-right p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        borderRadius: '5px',

                                    }} onClick={(v) => this.nextPage(v)}>
                                        <label><FaArrowRight style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}</label>
                                    </button>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    )
                }

                if (this.state.state === 2) {
                    return (
                        <MDBRow className="justify-content-center align-items-center h-100 text-center">
                            <div className={'mt-4'}>
                                <img src='../../images/intro-2.png' className={'img-fluid'}/>
                            </div>
                            <div className={'m-3'}>
                                <p style={{
                                    marginTop: '20px',
                                    color: 'white',
                                    fontSize: '15px',
                                    direction: 'ltr',
                                    textAlign: 'justify'}}>
                                    {this.props.i18n.t('welcome_second_msg')}
                                </p>
                            </div>

                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-right p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        direction: 'ltr',
                                        borderRadius: '5px',
                                    }} onClick={(v) => this.nextPage(v)}>
                                        <label><FaArrowRight style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}</label>
                                    </button>
                                </div>
                            </MDBCol>
                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-left p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        direction: 'ltr',
                                        color: '#fff',
                                        borderRadius: '5px',
                                    }} onClick={(v) => this.previousPage(v)}>
                                        <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft style={{marginRight: '4px'}}/></label>
                                    </button>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    )
                }

                if (this.state.state === 3) {
                    return (
                        <MDBRow className="justify-content-center align-items-center h-100 text-center">
                            <div className={'mt-4'}>
                                <img src='../../images/intro-3.png' className={'img-fluid'}/>
                            </div>
                            <div className={'m-3'}>
                                <p style={{
                                    color: 'white',
                                    marginTop: '20px',
                                    fontSize: '15px',
                                    direction: 'ltr',
                                    textAlign: 'center'}}>
                                    {this.props.i18n.t('welcome_third_msg')}
                                </p>
                            </div>

                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-right p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        borderRadius: '5px',
                                    }} onClick={(v) => this.nextPage(v)}>
                                        <label><FaArrowRight style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}</label>
                                    </button>
                                </div>
                            </MDBCol>
                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-left p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        direction: 'ltr',
                                        borderRadius: '5px',
                                    }} onClick={(v) => this.previousPage(v)}>
                                        <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft style={{marginRight: '4px'}}/></label>
                                    </button>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    )
                }

                if (this.state.state === 4) {
                    return (
                        <MDBRow className="justify-content-center align-items-center h-100 text-center">
                            <div className={'mt-4'}>
                                <img src='../../images/intro-end.png' className={'img-fluid'}/>
                            </div>
                            <div className={'m-3'}>
                                <p style={{
                                    color: 'white',
                                    direction: 'ltr',
                                    textAlign: 'center'}}>
                                    {this.props.i18n.t('welcome_fourth_msg')}
                                </p>
                            </div>

                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-left p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        direction: 'ltr',
                                        color: '#fff',
                                        borderRadius: '5px',
                                    }} onClick={(v) => this.confirmBTN(v)}>
                                        <label style={{marginLeft: '4px', marginRight: '4px'}}>{this.props.i18n.t('welcome_ok_btn')}</label>
                                    </button>
                                </div>
                            </MDBCol>
                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-left p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        direction: 'ltr',
                                        borderRadius: '5px',
                                    }} onClick={(v) => this.previousPage(v)}>
                                        <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft style={{marginRight: '4px'}}/></label>
                                    </button>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    )
                }
            }else {
                if (this.state.state === 1) {
                    return (
                        <MDBRow className="justify-content-center align-items-center h-100 text-center ">
                            <div>
                                <img src='../../images/intro-1.png' className={'img-fluid'}/>
                            </div>
                            <div className={'m-3'}>
                                <p style={{
                                    color: 'white',
                                    paddingRight: '70px',
                                    paddingLeft: '70px',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    direction: 'ltr',
                                    fontSize: '30px'
                                }}>
                                    {this.props.i18n.t('welcome_to_msg_intro')}
                                </p>
                                <p style={{color: 'white', direction: 'ltr', paddingRight: '70px', paddingLeft: '70px', textAlign: 'center'}}>
                                    {this.props.i18n.t('welcome_to_msg2')}
                                </p>
                            </div>

                            <MDBCol>
                                <div className={'text-right p-4 ltr'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        direction: 'rtl',
                                        borderRadius: '5px',
                                        width: '10%'
                                    }} onClick={(v) => this.nextPage(v)}>
                                        <label><FaArrowRight style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}</label>
                                    </button>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    )
                }

                if (this.state.state === 2) {
                    return (
                        <MDBRow className="justify-content-center align-items-center h-100 text-center ltr">
                            <div style={{marginLeft: '10%'}}>
                                <img src='../../images/intro-2.png' className={'img-fluid'}/>
                            </div>
                            <div className={'m-3'}>
                                <p style={{color: 'white',
                                    paddingRight: '50px',
                                    // paddingLeft: '70px',
                                    marginLeft: '10%',
                                    direction: 'ltr',
                                    textAlign: 'center'}}>
                                    {this.props.i18n.t('welcome_second_msg')}
                                </p>
                            </div>

                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-right p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        borderRadius: '5px',
                                        direction: 'rtl',
                                        width: '25%'
                                    }} onClick={(v) => this.previousPage(v)}>
                                        <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft style={{marginRight: '4px'}}/></label>
                                    </button>
                                </div>
                            </MDBCol>
                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-left p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        direction: 'rtl',
                                        borderRadius: '5px',
                                        width: '25%'
                                    }} onClick={(v) => this.nextPage(v)}>
                                        <label><FaArrowRight style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}</label>
                                    </button>
                                </div>
                            </MDBCol>

                        </MDBRow>
                    )
                }

                if (this.state.state === 3) {
                    return (
                        <MDBRow className="justify-content-center align-items-center h-100 text-center">
                            <div style={{marginLeft: '10%'}}>
                                <img src='../../images/intro-3.png' className={'img-fluid'}/>
                            </div>
                            <div className={'m-3'}>
                                <p style={{color: 'white',
                                    marginLeft: '10%',
                                    direction: 'ltr',
                                    paddingRight: '70px', paddingLeft: '70px', textAlign: 'center'}}>
                                    {this.props.i18n.t('welcome_third_msg')}
                                </p>
                            </div>


                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-right p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        direction: 'rtl',
                                        borderRadius: '5px',
                                        width: '25%'
                                    }} onClick={(v) => this.previousPage(v)}>
                                        <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft style={{marginRight: '4px'}}/></label>
                                    </button>
                                </div>
                            </MDBCol>
                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-left p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        direction: 'rtl',
                                        borderRadius: '5px',
                                        width: '25%'
                                    }} onClick={(v) => this.nextPage(v)}>
                                        <label><FaArrowRight style={{marginLeft: '4px'}}/> {this.props.i18n.t('welcome_next_btn')}</label>
                                    </button>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    )
                }

                if (this.state.state === 4) {
                    return (
                        <MDBRow className="justify-content-center align-items-center h-100 text-center">
                            <div>
                                <img src='../../images/intro-end.png' className={'img-fluid'}/>
                            </div>
                            <div className={'m-3'}>
                                <p style={{color: 'white', direction: 'ltr', paddingRight: '70px', paddingLeft: '70px', textAlign: 'center'}}>
                                    {this.props.i18n.t('welcome_fourth_msg')}
                                </p>
                            </div>

                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-right p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        direction: 'rtl',
                                        borderRadius: '5px',
                                        width: '25%'
                                    }} onClick={(v) => this.previousPage(v)}>
                                        <label> {this.props.i18n.t('welcome_back_btn')} <FaArrowLeft style={{marginRight: '4px'}}/></label>
                                    </button>
                                </div>
                            </MDBCol>
                            <MDBCol className={'align-self-center mt-4'}>
                                <div className={'text-left p-4'}>
                                    <button className="border border-white p-2" style={{
                                        fontSize: '10px',
                                        background: 'none',
                                        color: '#fff',
                                        direction: 'rtl',
                                        borderRadius: '5px',
                                        width: '25%'
                                    }} onClick={(v) => this.confirmBTN(v)}>
                                        <label style={{marginLeft: '4px', marginRight: '4px'}}>{this.props.i18n.t('welcome_ok_btn')}</label>
                                    </button>
                                </div>
                            </MDBCol>

                        </MDBRow>
                    )
                }
            }
        }
    }

    nextPage(st){
        this.setState({
            state: this.state.state+1
        })
    }

    previousPage(st){
        this.setState({
            state: this.state.state-1
        })
    }

    confirmBTN(st){
        localStorage.setItem('intro', true)
        window.location.reload(true)
    }
}

export default withTranslation()(IntroPage);